import {AWS_DATASTORE_SCHEMA_NAMES} from "../constants.js";
import {getClientUid} from "../localStorage.js";
import * as AmplifyDbController from "../../amplify-common/datastore-db.js";

export const getLocations = async (clientUID, sortColumn = 'title', sortBy = 'asc') => {
    return await AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsLocations, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: clientUID},
    ], sortColumn, sortBy).then((querySnapshot) => {
        const list = {};
        querySnapshot.forEach((doc) => {
            list[doc.id] = doc;
        });
        return list;
    });
};

export const listenLocations = (render) => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsLocations, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()}
    ]);
};
