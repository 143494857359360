import * as AppUtilsFunc from '../database/utils.js';

import * as AwsCognitoSDK from "@aws-sdk/client-cognito-identity-provider";
import * as CryptoJS from "crypto-js";

const AWS_SDK_CONFIGS = {
    CREDENTIALS_OLD_FORMAT: 'U2FsdGVkX1+yt/SBzC1O7SXsP0zAeK6diRIsML6+1bdni44mzK9fHQwI+cowqzn/W/Y/kTgB3IlalCtKMaGDWGoIL+wKhlToJ7pAAT8S11ZJxTGAE9kkhc8MkTwBc40kHpDvF6TQ9TUg4tYhDzRrby+OOPVjlZgiKdve8FqX0LJBGuU6TKmGkdiwGuWn8Xdn',
    CREDENTIALS: 'U2FsdGVkX19p3PPjsrqz2cYWUKWa+yqbTWb0RqL1amR5ufl0xnN9IPADXSL2mHJjjASgKH7I4y35EgckMdOtqYpNSRT7HmFUkO+OuXwwBNoOm285EmD+49yzbTxWs5+3umWV1RmOoeQ9iROh9+3vcj2wU4g2h+6wd2rMFvIdzXJuuLOX1FXOIHz54xgFSEcPSVdWI+Yms3d/X4rn4fT/IQ==',
    USER_POOL_ID: 'eu-north-1_D8njL84el'
};

/**
 * Retrieves the configuration settings for the admin client.
 *
 * This function retrieves the encrypted credentials from the AWS_SDK_CONFIGS.CREDENTIALS variable
 * and decrypts them using the 'aswedsk' encryption key. The decrypted credentials are then parsed
 * as a JSON object.
 *
 * @returns {Object} The admin client configuration settings as a JSON object.
 */
export const getAdminClientConfigs = () => {
    const bytes = CryptoJS.AES.decrypt(AWS_SDK_CONFIGS.CREDENTIALS, 'aswedsk');
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

/**
 * Creates a new user in an AWS Cognito user pool.
 * @param email
 * @param password
 * @param permanentPassword
 * @returns {Promise<boolean>}
 */
export const AWS_createNewUser = async (email, password, permanentPassword = false) => {

    const cognitoClient = new AwsCognitoSDK.CognitoIdentityProviderClient(getAdminClientConfigs());

    const createUserCommand = new AwsCognitoSDK.AdminCreateUserCommand({

        "UserPoolId": AWS_SDK_CONFIGS.USER_POOL_ID,

        "Username": email,

        "TemporaryPassword": password, // Temporary password

        "UserAttributes": [
            {
                "Name": 'email',
                "Value": email,
            },
            {
                "Name": 'email_verified',
                "Value": 'true', // Bypass email verification
            },
        ],

        "MessageAction": 'SUPPRESS', // Suppress sending the verification/welcome email

    });

    let doPermanentPasswordOperation = false;

    const createUserResponse = await cognitoClient.send(createUserCommand)
        .then((data) => {
            console.log('AWS_createNewUser AdminCreateUserCommand response: ', data);
            doPermanentPasswordOperation = true;
            return data?.User?.Username || false;
        }).catch((err) => {
            console.log('AWS_createNewUser AdminCreateUserCommand error: ', err);
            doPermanentPasswordOperation = false;
            if (typeof $?.jnoty !== 'undefined') {
                AppUtilsFunc.showAwsExceptionMessage(error);
            } else {
                AppUtilsFunc.showAwsExceptionToastMessage(error);
            }
            return false;
        });

    if (permanentPassword && doPermanentPasswordOperation) {

        const permanentPasswordCommand = new AwsCognitoSDK.AdminSetUserPasswordCommand({

            "UserPoolId": AWS_SDK_CONFIGS.USER_POOL_ID,

            "Username": email,

            "Password": password,

            "Permanent": true,

        });

        await cognitoClient.send(permanentPasswordCommand)
            .then((data) => {
                console.log('AWS_createNewUser AdminSetUserPasswordCommand response: ', data);
                return true;
            }).catch((err) => {
                console.log('AWS_createNewUser AdminSetUserPasswordCommand error: ', err);
                return false;
            });
    }

    return createUserResponse;
};

/**
 * Updates the email address for a user in an AWS Cognito user pool.
 *
 * @async
 * @param {string} oldEmail - The current email address of the user.
 * @param {string} newEmail - The new email address to update.
 * @returns {Promise<boolean>} - A promise that resolves to a boolean value indicating whether the*/
export const AWS_updateAuthEmail = async (oldEmail, newEmail) => {

    const cognitoClient = new AwsCognitoSDK.CognitoIdentityProviderClient(getAdminClientConfigs());

    const cognitoCommand = new AwsCognitoSDK.AdminUpdateUserAttributesCommand({

        "UserPoolId": AWS_SDK_CONFIGS.USER_POOL_ID,

        "Username": oldEmail,

        "UserAttributes": [
            {
                "Name": "email",
                "Value": newEmail
            },
            {
                "Name": "email_verified",
                "Value": "true"
            }
        ],

    });

    return await cognitoClient.send(cognitoCommand).then((data) => {
        console.log('AWS_updateAuthEmail', data);
        return true;
    }).catch((err) => {
        console.log('AWS_updateAuthEmail error', err);
        return false;
    });
};

/**
 * Updates the password for a user in the AWS Cognito User Pool.
 *
 * @param {string} email - The email address associated with the user.
 * @param {string} newPass - The new password for the user.
 * @returns {Promise<boolean>} A promise that resolves to `true` if the password is updated successfully, or `false` if an error occurs.
 *
 * @throws {Error} If an error occurs while updating the password.
 */
export const AWS_updateAuthPassword = async (email, newPass) => {

    const cognitoClient = new AwsCognitoSDK.CognitoIdentityProviderClient(getAdminClientConfigs());

    const cognitoCommand = new AwsCognitoSDK.AdminSetUserPasswordCommand({

        "UserPoolId": AWS_SDK_CONFIGS.USER_POOL_ID,

        "Username": email,

        "Password": newPass,

        "Permanent": true,

    });

    return await cognitoClient.send(cognitoCommand)
        .then((data) => {
            console.log('AWS_updateAuthPassword', data);
            return true;
        }).catch((err) => {
            console.log('AWS_updateAuthPassword error', err);
            return false;
        });
};

/**
 * Confirms a new account in AWS Cognito.
 *
 * @param {string} confirmUsername - The username to confirm.
 *
 * @returns {Object} - An object with confirmation status.
 * @property {boolean} confirmUser - Indicates if the user is confirmed.
 * @property {boolean} confirmUserAttribute - Indicates if the email is verified for the user.
 *
 * @throws {Error} - If there is an error during the confirmation process.
 */
export const AWS_confirmNewAccount = async (confirmUsername) => {

    let status = {confirmUser: false, confirmUserAttribute: false};

    try {

        console.log('confirmNewAccount confirmUsername', confirmUsername);

        if (confirmUsername) {

            const cognitoClient = new AwsCognitoSDK.CognitoIdentityProviderClient(getAdminClientConfigs());

            const cognitoConfirmUserCommand = new AwsCognitoSDK.AdminConfirmSignUpCommand({

                "UserPoolId": AWS_SDK_CONFIGS.USER_POOL_ID,

                "Username": confirmUsername,

                "ClientMetadata": {},

            });

            status.confirmUser = await cognitoClient.send(cognitoConfirmUserCommand)
                .then(async (data) => {

                    console.log(`Successfully confirmed user: `, confirmUsername, data);

                    return true;

                }).catch((err) => {

                    console.log(`Confirm sign up: ${confirmUsername} failed: `, err, err?.stack);

                    return false;

                });

            const cognitoUpdateAttributeCommand = new AwsCognitoSDK.AdminUpdateUserAttributesCommand({

                "UserPoolId": AWS_SDK_CONFIGS.USER_POOL_ID,

                "Username": confirmUsername,

                "UserAttributes": [
                    {
                        "Name": "email_verified",
                        "Value": "true"
                    }
                ],

            });

            status.confirmUserAttribute = await cognitoClient.send(cognitoUpdateAttributeCommand)
                .then(async (data) => {

                    console.log(`Successfully email verified user: `, confirmUsername, data);

                    return true;

                }).catch((err) => {

                    console.log(`Email Verification: ${confirmUsername} failed: `, err, err?.stack);

                    return false;

                });

            return status;
        }

        return status;

    } catch (e) {
        console.log('confirmNewAccount catch error: ', e);

        return status;
    }
};

/**
 * Retrieves a list of unconfirmed users from the Cognito User Pool.
 * If unconfirmed users found then perform confirm users function
 *
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 *
 * @throws {Error} If there is an error retrieving the unconfirmed users.
 */
export const getUnConfirmedUsers = async () => {

    const cognitoClient = new AwsCognitoSDK.CognitoIdentityProviderClient(getAdminClientConfigs());

    const cognitoCommand = new AwsCognitoSDK.ListUsersCommand({

        "Limit": 60,

        "UserPoolId": AWS_SDK_CONFIGS.USER_POOL_ID,

        // "Filter": "UserStatus = UNCONFIRMED"

        "Filter": 'cognito:user_status = "UNCONFIRMED"',

    });

    await cognitoClient.send(cognitoCommand)
        .then(async (data) => {

            let unConfirmedUsers = data?.Users || [];

            console.log(`Un-confirmed users: `, JSON.stringify(unConfirmedUsers));

            if (unConfirmedUsers.length > 0) {
                await confirmThisUsers(unConfirmedUsers);
            }

        }).catch((err) => {
            console.log(`Get Un-confirmed users error: `, err, err.stack);
        });
};

/**
 * Confirms a list of users.
 *
 * The confirmThisUsers function is responsible for confirming a list of unconfirmed users.
 * It iterates through the list of unConfirmedUsers and, for each user, it logs the index and the value of the third attribute in the user object.
 * It then calls the AWS_confirmNewAccount function with the value of the third attribute as an argument.
 *
 * After confirming all the users in the current batch, the function calls the getUnConfirmedUsers function to get the next batch of unconfirmed users.
 *
 * @param {Array} unConfirmedUsers - The list of unconfirmed users to be confirmed.
 *
 * @return {Promise<void>} - A Promise that resolves when all the users are confirmed.
 */
const confirmThisUsers = async (unConfirmedUsers) => {

    await AppUtilsFunc.customAsyncForEach(unConfirmedUsers, async (user, index) => {

        console.log(`Un-confirmed user: `, index, user.Attributes[2].Value);

        await AWS_confirmNewAccount(user.Attributes[2].Value);

    });

    // Get next batch of un-confirmed users
    await getUnConfirmedUsers();
};

/**
 * Deletes a user from the AWS Cognito user pool.
 *
 * @async
 * @param {string} userEmail - The email address of the user to be deleted.
 * @returns {boolean} - Returns true if the user was successfully deleted, otherwise returns false.
 */
export const deleteAwsCognitoUser = async (userEmail) => {
    if (userEmail) {

        const cognitoClient = new AwsCognitoSDK.CognitoIdentityProviderClient(getAdminClientConfigs());

        const cognitoCommand = new AwsCognitoSDK.AdminDeleteUserCommand({

            "UserPoolId": AWS_SDK_CONFIGS.USER_POOL_ID,

            "Username": userEmail,

        });

        return await cognitoClient.send(cognitoCommand)
            .then((data) => {
                console.log(`Successfully deleted user: `, userEmail, data);
                return true;
            }).catch((err) => {
                console.log(`Delete user: ${userEmail} failed: `, err, err.stack);
                return false;
            });
    }

    return false;
};

/**
 * Retrieves user data from AWS Cognito based on the specified user email.
 *
 * @param {string} userEmail - The email of the user to retrieve data for.
 * @returns {Promise<any>|boolean} - A Promise that resolves to the user data if successful, otherwise false.
 */
export const getUserData = async (userEmail) => {
    if (userEmail) {

        const cognitoClient = new AwsCognitoSDK.CognitoIdentityProviderClient(getAdminClientConfigs());

        const cognitoCommand = new AwsCognitoSDK.AdminGetUserCommand({

            "UserPoolId": AWS_SDK_CONFIGS.USER_POOL_ID,

            "Username": userEmail,

        });

        return await cognitoClient.send(cognitoCommand)
            .then((data) => {
                // console.log(`Successfully got user: `, userEmail, data);
                return data;
            }).catch((err) => {
                // console.log(`Get user: ${userEmail} failed: `, err, err.stack);
                return false;
            });
    }

    return false;
};

/**
 * Retrieves a list of users from the Cognito user pool based on the provided email address.
 *
 * @param {string} userEmail - The email address of the user to retrieve.
 * @returns {Promise<boolean>} - A promise that resolves to true if the user is successfully retrieved, and false otherwise.
 * @throws {Error} - If there was an error retrieving the user.
 */
export const listUsersData = async (userEmail) => {
    if (userEmail) {

        const cognitoClient = new AwsCognitoSDK.CognitoIdentityProviderClient(getAdminClientConfigs());

        const cognitoCommand = new AwsCognitoSDK.ListUsersCommand({

            "AttributesToGet": [
                'username'
            ],

            "UserPoolId": AWS_SDK_CONFIGS.USER_POOL_ID,

            "Filter": `email = "${userEmail}"`,

        });

        return await cognitoClient.send(cognitoCommand)
            .then((data) => {
                console.log(`Successfully got user: `, userEmail, data);
                return true;
            }).catch((err) => {
                console.log(`Get user: ${userEmail} failed: `, err, err.stack);
                return false;
            });
    }

    return false;
};
