import {AWS_DATASTORE_SCHEMA_NAMES, RES_STATUS} from "../constants.js";
import * as RenderSystem from '../../render/render-system.js';
import * as AmplifyAuth from "../../amplify-common/auth-db.js";
import * as AmplifyDb from "../../amplify-common/datastore-db.js";
import * as AwsAdmin from "../../amplify-common/aws-admin-functions.js";
import {isActiveInternetConnection, showToastMessage} from "../../../../common/utils/utils.js";

export const checkIsLoggedIn = (callback) => {
    AmplifyAuth.isLoggedIn(true).then((user) => {
        if (user) {
            callback(true, user);
        } else {
            callback(false);
        }
    });
};

export const isLoggedin = async (callback) => {
    AmplifyAuth.isLoggedIn(true).then(callback);
};

export const reAuthenticateUser = async (callback) => {
    let codeSent = await AmplifyAuth.sendVerificationCodeToCurrentAuthUser();
    if (codeSent) {
        bootbox.prompt({
            title: 'We sent a verification code on your registered email address. Please check your email and verify here with the code.',
            message: '<p class="text-bold text-danger">Check that it has not arrived in the SPAM folder of your email.</p>',
            centerVertical: true,
            closeButton: false,
            required: true,
            callback: function (verificationCode) {
                if (verificationCode) {
                    AmplifyAuth.verifyCurrentUserAuthByCode(verificationCode).then((codeVerified) => {
                        return callback(codeVerified);
                    });
                }
            }
        });
    } else {
        RenderSystem.showAlertMessage('Please login again to perform any operation.', 'danger');
        return callback(false);
    }
};

export const reAuthenticateUserByPassword = (email, callback) => {
    if (email) {
        bootbox.prompt({
            title: 'Please enter your account password to perform this operation.',
            centerVertical: true,
            closeButton: false,
            required: true,
            callback: function (userPassword) {
                if (userPassword) {

                    RenderSystem.showLoadingDataMessage('Please wait a moment...');

                    AmplifyDb.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SubscriberAdmin, [
                        {fieldName: 'email', conditionType: 'eq', fieldValue: email},
                        {fieldName: 'password', conditionType: 'eq', fieldValue: userPassword},
                    ]).then((userData) => {
                        if (userData.length > 0) {
                            callback(true);
                        } else {
                            RenderSystem.showAlertMessage('Invalid password. Please try again.', 'danger');
                            callback(false);
                        }
                    }).catch((error) => {
                        RenderSystem.showAlertMessage('Error verifying password. Please try again.', 'danger');
                        callback(false);
                    });
                }
            }
        });
    } else {
        RenderSystem.showAlertMessage('Please login again to perform any operation.', 'danger');
        callback(false);
    }
};

export const deleteUIDAccount = async (clientEmail, callback) => {
    AwsAdmin.deleteAwsCognitoUser(clientEmail).then((status) => {
        if (status) {
            RenderSystem.showAlertMessage('Successfully deleted the UID account.');
        } else {
            RenderSystem.showAlertMessage('Error deleting UID account.', 'danger');
        }
        callback(status);
    });
};

export const loggedinUser = (callback) => {
    AmplifyAuth.isLoggedIn(true).then(callback);
};

export const isSubscriberAdminUser = async (email) => {
    return await AmplifyDb.getDataFromAwsDataStoreByCondition(AWS_DATASTORE_SCHEMA_NAMES.SubscriberAdmin, 'eq', 'email', email).then((subscriberAdminData) => {
        return subscriberAdminData.length > 0;
    });
};

export const isSubscriberAdminUserById = async (uid) => {
    return await AmplifyDb.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SubscriberAdmin, uid).then((subscriberAdminData) => {
        return !!subscriberAdminData?.email;
    });
};

export const getSubscriberAdminUser = async (email) => {
    return await AmplifyDb.getDataFromAwsDataStoreByCondition(AWS_DATASTORE_SCHEMA_NAMES.SubscriberAdmin, 'eq', 'email', email).then((subscriberAdminData) => {
        return subscriberAdminData.length > 0 ? subscriberAdminData[0] : false;
    });
};

export const getSubscriberAdminUserById = async (id) => {
    return await AmplifyDb.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SubscriberAdmin, id).then((subscriberAdminData) => {
        return subscriberAdminData?.saId ? subscriberAdminData : false;
    });
};

export const deleteSubscriberAdmin = async (id) => {
    return await AmplifyDb.deleteDataFromAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SubscriberAdmin, id);
};

export const logoutUser = () => {
    AmplifyAuth.logoutUser().then(() => {
        return RES_STATUS.SUCCESS;
    }).catch((error) => {
        return undefined;
    });
};

export const loginUser = async (email, password, returnUid = false) => {
    return await AmplifyAuth.loginUser(email, password, false).then((user) => {
        if (user) {
            return returnUid ? user.username : email;
        } else {
            return false;
        }
    }).catch((error) => {
        return error;
    });
};

export const addAdminUser = async (user) => {
    let createdUserId = await AwsAdmin.AWS_createNewUser(user.email, user.password, true);

    if (createdUserId === false) {
        return false;
    }

    user['saId'] = createdUserId;

    return AmplifyDb.insertDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SubscriberAdmin, user);
};

export const getSubscriberAdminData = () => {
    let credentials = localStorage.getItem('sAdminAuth');
    if (credentials) {
        return JSON.parse(credentials);
    } else {
        RenderSystem.hideLoadingDataMessage(true);
        RenderSystem.showAlertMessage('Please login again to perform the operation.', 'danger');
        return false;
    }
};

export const checkSubscriberAdminAndLogin = async (callbackFunc) => {
    let credentials = localStorage.getItem('sAdminAuth');
    if (credentials) {
        credentials = JSON.parse(credentials);
        let loginUserData = await loginUser(credentials.email, credentials.password);
        if (!await isSubscriberAdminUser(loginUserData)) {
            RenderSystem.hideLoadingDataMessage(true);
            RenderSystem.showAlertMessage('Please login again to perform any operation.', 'danger');
        } else {
            RenderSystem.hideLoadingDataMessage(true);
            callbackFunc();
        }
    } else {
        RenderSystem.hideLoadingDataMessage(true);
        RenderSystem.showAlertMessage('Please login again to perform the operation.', 'danger');
    }
};

export const listenSubscriberAdmins = (render) => {
    AmplifyDb.listenAllDataFromCollection(AWS_DATASTORE_SCHEMA_NAMES.SubscriberAdmin, render, 'createdAt', 'desc', 'saId');
};

export const updateUserWithCredentials = async (user, updateEmail = false, updatePassword = false) => {
    var result = {};

    if (isActiveInternetConnection()) {

        if (updateEmail && user?.oldEmail) {

            let emailExist = await AwsAdmin.getUserData(user.email);

            if (emailExist === false) {

                let statusEmailUpdate = await AwsAdmin.AWS_updateAuthEmail(user?.oldEmail, user.email);

                if (!statusEmailUpdate) {

                    result['status'] = RES_STATUS.FAILED;

                    return result;
                }

            } else {

                result['status'] = RES_STATUS.EMAIL_EXIST;

                return result;
            }
        }

        if (updatePassword) {

            let statusPasswordUpdate = await AwsAdmin.AWS_updateAuthPassword(user.email, user.password);

            if (statusPasswordUpdate === false) {
                result['status'] = RES_STATUS.FAILED;
                return result;
            }
        }

    } else {

        if (updateEmail || updatePassword) {
            delete user.email;
            delete user.password;
            showToastMessage("Notice", "No internet connection. You cannot update employee account email or password.", "error");
        }
    }

    if (user?.oldEmail) {
        delete user.oldEmail;
    }

    if (user?.oldPassword) {
        delete user.oldPassword;
    }

    return await AmplifyDb.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SubscriberAdmin, user.saId, user).then(status => {
        if (status) {
            result['status'] = RES_STATUS.SUCCESS;
        }
        return result;
    });
};

export const loginWithIdOrEmailPassword = (uid, email = null, password = null) => {
    return AmplifyDb.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SubscriberAdmin, uid).then((data) => {
        if (data) {
            if (email == null) {
                email = data.email;
            }
            if (password == null) {
                password = data.password;
            }
            return AmplifyAuth.loginUser(email, password, false);
        }
    });
};
