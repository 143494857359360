import {AWS_DATASTORE_SCHEMA_NAMES, USER_ROLE, SALEPRO_APP_ENVIRONMENT} from '../database/constants.js';
import {login, setClientUid} from '../database/localStorage.js';
import {customAsyncForEach} from '../database/utils.js';
import {checkImageExistOrNot} from './common.js';
import {setRatingStar} from './client-ratings.js';
import * as ClientController from '../database/db/salepro-clients-db.js';
import * as SuggestionController from '../database/db/suggestion-db.js';
import * as UserController from "../database/db/subscriber-admin-db.js";
import * as LocationController from "../database/db/location-db.js";
import * as SalePRO_ClientsController from "../database/db/salepro-clients-db.js";
import * as RenderSystem from '../render/render-system.js';
import * as AmplifyAuthController from "../amplify-common/auth-db.js";
import * as AmplifyDbController from "../amplify-common/datastore-db.js";
import * as Aws_AdminSDK from "../amplify-common/aws-admin-functions.js";

let g_subscriber_admins = {};
let g_clients = {};
let g_suggestions = {};
let g_loggedInUser = null;

export async function initSuggestions() {
    SuggestionController.listenAllSuggestions((data) => {
        g_suggestions = data;
        renderSuggestions();
    });

    let filterElem = $('#myTabContent #suggestions #filterSuggestionByClientUid');
    filterElem.val('').trigger('change');
    if (filterElem.data('select2')) {
        filterElem.select2('destroy');
    }
    filterElem.off('change');
    filterElem.on('change', function () {
        renderSuggestions($(this).val());
    });
    filterElem.select2({
        templateResult: formatOptionTemplate,
        templateSelection: formatOptionSelectionTemplate
    });
}

export function renderSuggestions(selectedClientUid = null) {
    let newSuggestionsCount = Object.values(g_suggestions).filter((data) => data.read === false), suggestionsElement = [];

    $('#suggestions-tab .newSuggestionCount').html(newSuggestionsCount.length);

    Object.values(g_suggestions).forEach((data) => {
        let operationIconClasses = data.read === true ? 'unread-notification' : 'read-notification';
        let operationImage = data.read === true ? './images/icons8-received-64.png' : './images/icons8-new-unread-email-48.png';
        let messageClass = data.read === true ? 'unread' : 'read';

        let pushRow = selectedClientUid ? (data.clientUid === selectedClientUid) : true;

        if (pushRow && g_clients[data.clientUid] !== undefined) {
            let selectedSections = '';
            if (data.section instanceof Array) {
                data.section.forEach((section, index) => {
                    let sectionDetails = getImageBySection(section);
                    selectedSections += `<img src="${sectionDetails.imagePath}" alt="" width="20" height="20" class="mr-2">${sectionDetails.sectionName}`;
                    if (index < data.section.length - 1) {
                        selectedSections += `<hr style="margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;">`;
                    }
                });
            } else {
                let sectionDetails = getImageBySection(data.section);
                selectedSections = `<img src="${sectionDetails.imagePath}" alt="" width="20" height="20" class="mr-2">${sectionDetails.sectionName}`;
            }
            let uphoto = g_clients[data.clientUid].photo ? checkImageExistOrNot(g_clients[data.clientUid].photo) : './images/no-image-found.png';
            let bLogo = g_clients[data.clientUid].brandLogo ? checkImageExistOrNot(g_clients[data.clientUid].brandLogo) : './images/no-image-found.png';
            let newElement = $(`<tr class="${operationIconClasses}">
                                <td class="${messageClass}" style="cursor: pointer;text-align: center"><img src="${operationImage}" alt="" width="20" height="20"></td>
                                <td class="${messageClass}" style="cursor: pointer;text-align: center"><img src="${uphoto}" style="border-radius: 50px;" alt="" width="50" height="50"><img src="${bLogo}" style="border-radius: 50px;" alt="" width="40" height="40"></td>
                                <td class="${messageClass}" style="cursor: pointer">${g_clients[data.clientUid].name}</td>
                                <td class="${messageClass}" style="cursor: pointer">${selectedSections}</td>
                                <td class="${messageClass}" style="cursor: pointer">${data.message}</td>
                            </tr>`);
            newElement.find('td.unread').on('click', unreadNotificationMessage.bind(this, data));
            newElement.find('td.read').on('click', readNotificationMessage.bind(this, data));
            suggestionsElement.push(newElement);
        }
    });

    if (suggestionsElement.length === 0) {
        suggestionsElement.push((`<tr><td colspan="5" class="text-center">No suggestion received yet.</td></tr>`));
    }

    $('#myTabContent #suggestions #suggestionsTable tbody').empty().html(suggestionsElement);
}

const unreadNotificationMessage = (notification) => {
    SuggestionController.updateSuggestion(notification.id, {read: false});
};

const readNotificationMessage = (notification) => {
    SuggestionController.updateSuggestion(notification.id, {read: true});
};

const getImageBySection = (optId) => {
    let returnData = {imagePath: null, sectionName: null};
    switch (optId) {
        case 'admin_dashboard':
            returnData.sectionName = 'BACK OFFICE - Dashboard';
            returnData.imagePath = './images/menu/dashboard.png';
            break;
        case 'admin_locations':
            returnData.sectionName = 'BACK OFFICE - Locations';
            returnData.imagePath = './images/menu/locations.png';
            break;
        case 'admin_customers':
            returnData.sectionName = 'BACK OFFICE - Customers';
            returnData.imagePath = './images/menu/customers.png';
            break;
        case 'admin_employees':
            returnData.sectionName = 'BACK OFFICE - Employees';
            returnData.imagePath = './images/menu/employees.png';
            break;
        case 'admin_inventory':
            returnData.sectionName = 'BACK OFFICE - Inventory';
            returnData.imagePath = './images/menu/inventory.png';
            break;
        case 'admin_reports':
            returnData.sectionName = 'BACK OFFICE - Reports';
            returnData.imagePath = './images/menu/accounting.png';
            break;
        case 'admin_appointments':
            returnData.sectionName = 'BACK OFFICE - Appointments';
            returnData.imagePath = './images/menu/appointments.png';
            break;
        case 'admin_online_store':
            returnData.sectionName = 'BACK OFFICE - Online Store';
            returnData.imagePath = './images/menu/storeweb.png';
            break;
        case 'admin_my_account':
            returnData.sectionName = 'BACK OFFICE - My Account';
            returnData.imagePath = './images/menu/myaccount.png';
            break;
        case 'admin_feedback':
            returnData.sectionName = 'BACK OFFICE - Feedback';
            returnData.imagePath = './images/menu/feedback.png';
            break;
        case 'admin_user_guide':
            returnData.sectionName = 'BACK OFFICE - User Guide';
            returnData.imagePath = './images/menu/guide.jpg';
            break;

        case 'pos_sales':
            returnData.sectionName = 'POS - Sales';
            returnData.imagePath = './images/menu/sales.png';
            break;
        case 'pos_customers':
            returnData.sectionName = 'POS - Customers';
            returnData.imagePath = './images/menu/customers.png';
            break;
        case 'pos_employees':
            returnData.sectionName = 'POS - Employees';
            returnData.imagePath = './images/menu/employees.png';
            break;
        case 'pos_appointments':
            returnData.sectionName = 'POS - Appointments';
            returnData.imagePath = './images/menu/appointments.png';
            break;
        case 'pos_online_store':
            returnData.sectionName = 'POS - Online Store';
            returnData.imagePath = './images/menu/storeweb.png';
            break;
        case 'pos_inventory':
            returnData.sectionName = 'POS - Inventory';
            returnData.imagePath = './images/menu/inventory.png';
            break;
        case 'pos_close_day':
            returnData.sectionName = 'POS - Close Day';
            returnData.imagePath = './images/menu/closeday.png';
            break;
        case 'pos_reports':
            returnData.sectionName = 'POS - Reports';
            returnData.imagePath = './images/menu/reports.png';
            break;
        case 'pos_feedback':
            returnData.sectionName = 'POS - Feedback';
            returnData.imagePath = './images/menu/feedback.png';
            break;
        case 'pos_clockin':
            returnData.sectionName = 'POS - Clockin';
            returnData.imagePath = './images/menu/clockin.png';
            break;
        case 'pos_websites':
            returnData.sectionName = 'POS - Websites';
            returnData.imagePath = './images/menu/websites.png';
            break;
        case 'pos_user_guide':
            returnData.sectionName = 'POS - User Guide';
            returnData.imagePath = './images/menu/guide.jpg';
            break;
    }

    return returnData;
};

/**
 * Listen and Render Client UIDs information's
 */
export function initClients(loggedInUser) {
    g_loggedInUser = loggedInUser;

    ClientController.listenClients(async (data) => {
        g_clients = data;
        await renderClients();
    });
    UserController.listenSubscriberAdmins((data) => {
        g_subscriber_admins = data;
        renderSubscriberAdmins();
    });
}

export async function renderClients(searchString = '') {
    try {
        if (Object.keys(g_clients).length === 0) {
            g_clients = await ClientController.getClients().then(data => JSON.parse(JSON.stringify(data)));
        }
        let clientsGridElements = [], clientsListElements = [], clientFilterOptions = '<option value="">Select a client account</option>', filterElem = $('#myTabContent #suggestions #filterSuggestionByClientUid');
        RenderSystem.hideLoadingDataMessage();
        if (Object.keys(g_clients).length) {
            for (const [key, value] of Object.entries(g_clients)) {
                clientFilterOptions += `<option value="${key}">${value.name ? `${value.name}` : ''}</option>`;
                let showData = true;
                if (searchString) {
                    showData = (value?.name ? value.name.match(new RegExp(searchString, 'ig')) !== null : false) || (value?.companyName ? value.companyName.match(new RegExp(searchString, 'ig')) !== null : false);
                }
                if (showData) {
                    let uphoto = value.photo ? checkImageExistOrNot(value.photo) : './images/no-image-found.png';
                    let bLogo = value.brandLogo ? checkImageExistOrNot(value.brandLogo) : './images/no-image-found.png';

                    let blockedAccountElement = value.accountStatus === 'blocked' ? '<div class="ribbon ribbon-top-left ribbon-danger"><span>BLOCKED</span></div>' : '';
                    let blockedAccountLabelElement = value.accountStatus === 'blocked' ? '<span class="badge badge-danger">BLOCKED</span>' : '';
                    let blockUnblockButtonContent = value.accountStatus === 'blocked' ? '<img src="./images/unblock.png" alt="" width="20" height="20"> Unblock' : '<img src="./images/block.png" alt="" width="20" height="20"> Block';

                    let clientAccountCard = `<div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12">`;
                    clientAccountCard += `<div class="card bg-light shadow client-card mb-3" data-id="${key}">${blockedAccountElement}`;
                    clientAccountCard += `<div class="card-header text-center text-bold">${value.name ? `${value.name}` : '<span class="text-danger">NO NAME GIVEN YET</span>'}</div>`;
                    clientAccountCard += `<div class="card-body">`;
                    clientAccountCard += `<div class="card-text">
                                            <div class="row" style="min-height: 125px;">
                                                 <div class="col-md-12 text-center">
                                                     <img class="rounded-circle" id="uphoto1" style="height: 100px;width: 100px" src="${uphoto}" alt="User Image">
                                                     <img class="rounded-circle" id="bLogo1" style="width: 75px;margin-left: -3px;height: 75px;" src="${bLogo}" alt="Brand Image">
                                                 </div>
                                             </div>
                                          </div>`;
                    if (value.name || value.phone) {
                        clientAccountCard += `<p class="card-text text-center mb-0">
                                                 ${value.name ? `${value.name}` : ''}
                                                 ${value.phone ? ` ${value.phone}` : ''}
                                             </p>`;
                    }
                    if (value.companyName) {
                        clientAccountCard += `<p class="card-text text-center mb-0">
                                                 ${value.companyName ? `${value.companyName}` : ''}
                                             </p>`;
                    }
                    if (value.companyAddress || value.cif) {
                        clientAccountCard += `<p class="card-text text-center mb-2">
                                                 ${value.companyAddress ? `${value.companyAddress}` : ''}
                                                 ${value.cif ? ` ${value.cif}` : ''}
                                             </p>`;
                    }
                    clientAccountCard += `<div class="card-text mb-0 text-center">
                                             <div id="reviewStars">
                                                 <span data-rating="1" data-uid="${value.clientUid}"><i class="${Number(value?.ratings || '0') >= 1 ? 'fa fa-star active' : 'fa fa-star-o'}"></i></span>
                                                 <span data-rating="2" data-uid="${value.clientUid}"><i class="${Number(value?.ratings || '0') >= 2 ? 'fa fa-star active' : 'fa fa-star-o'}"></i></span>
                                                 <span data-rating="3" data-uid="${value.clientUid}"><i class="${Number(value?.ratings || '0') >= 3 ? 'fa fa-star active' : 'fa fa-star-o'}"></i></span>
                                                 <span data-rating="4" data-uid="${value.clientUid}"><i class="${Number(value?.ratings || '0') >= 4 ? 'fa fa-star active' : 'fa fa-star-o'}"></i></span>
                                                 <span data-rating="5" data-uid="${value.clientUid}"><i class="${Number(value?.ratings || '0') >= 5 ? 'fa fa-star active' : 'fa fa-star-o'}"></i></span>
                                             </div>
                                         </div>`;
                    clientAccountCard += `</div>`;
                    clientAccountCard += `<div class="card-footer text-center p-2">
                                            <div class="btn-group" role="group" aria-label="Basic example">
                                              <button type="button" class="btn btn-outline-info text-bold showAccountIframe" data-uid="${key}"><i class="fa fa-eye"></i> View</button>
                                              <button type="button" class="btn btn-outline-warning text-bold cardBlockOrUnblockUID" data-account-status="${value.accountStatus}" data-uid="${key}">${blockUnblockButtonContent}</button>
                                              <button type="button" class="btn btn-outline-danger text-bold cardDeletedUID_Account" data-uid="${key}"><i class="fa fa-trash"></i> Delete</button>
                                            </div>
                                          </div>`;
                    clientAccountCard += `</div></div>`;

                    let $template = $(clientAccountCard);

                    $template.find('.client-card .card-header').unbind('click', showClientStoreData.bind(this, key));
                    $template.find('.client-card .card-body').bind('click', showClientStoreData.bind(this, key));
                    $template.find('.showAccountIframe').bind('click', showClientStoreData.bind(this, key));
                    $template.find('.cardDeletedUID_Account').bind('click', deleteClientAccount.bind(this, key));
                    $template.find('.cardBlockOrUnblockUID').bind('click', accountBlockOrUnblock.bind(this, $template.find('#cardDeletedUID_Account'), value.accountStatus, key));
                    clientsGridElements.push($template);

                    let clientLocations = await LocationController.getLocations(key);

                    let newTableRowElm = $(`<tr>
                                                <td>${value?.name || ''}</td>
                                                <td>${value?.email || ''}</td>
                                                <td>${value?.phone || ''}</td>
                                                <td>${value?.companyName || ''}</td>
                                                <td>${value?.companyAddress || ''}</td>
                                                <td class="text-center">${value?.ratings || '0'}</td>
                                                <td>${value.createdAt ? moment(value.createdAt).format('YYYY-MM-DD HH:mm:ss') : ''}</td>
                                                <td>${value.adminAppLastLogin ? moment(value.adminAppLastLogin).format('YYYY-MM-DD HH:mm:ss') : ''}</td>
                                                <td>${value.adminAppLastLogin ? moment(value.adminAppLastLogin).format('YYYY-MM-DD HH:mm:ss') : ''}</td>
                                                <td class="text-center">${Object.keys(clientLocations).length}</td>
                                                <td class="text-center">${blockedAccountLabelElement}</td>
                                                <td class="text-center">
                                                  <button type="button" class="btn btn-outline-info btn-sm text-bold show-account-iframe w-100"><i class="fa fa-eye"></i> View</button>
                                                  <button type="button" class="btn btn-outline-warning btn-sm text-bold card-block-or-unblock w-100 mt-2">${blockUnblockButtonContent}</button>
                                                  <button type="button" class="btn btn-outline-danger btn-sm text-bold card-deleted-account w-100 mt-2"><i class="fa fa-trash"></i> Delete</button>
                                                </td>
                                            </tr>`);

                    newTableRowElm.find('.show-account-iframe').bind('click', showClientStoreData.bind(this, key));
                    newTableRowElm.find('.card-block-or-unblock').bind('click', accountBlockOrUnblock.bind(this, $template.find('#cardDeletedUID_Account'), value.accountStatus, key));
                    newTableRowElm.find('.card-deleted-account').bind('click', deleteClientAccount.bind(this, key));
                    clientsListElements.push(newTableRowElm);
                }
            }
        }

        if (clientsListElements.length === 0) {
            clientsListElements.push(`<tr><td colspan="12" class="text-center">No data found.</td></tr>`);
        }

        $('#client-grid-wrapper').empty().html(clientsGridElements);
        $('#client-list-wrapper #clientsListTable tbody').empty().html(clientsListElements);

        filterElem.empty().html(clientFilterOptions);

    } catch (e) {
        console.error('Error: ', e.message);
        RenderSystem.hideLoadingDataMessage();
    }
}


const renderSubscriberAdmins = () => {
    let subscriberAdminsElements = [];

    Object.keys(g_subscriber_admins).forEach((key) => {

        let showData = false;

        if (g_loggedInUser?.type === 'super_admin' && g_subscriber_admins[key].type === 'super_admin') {
            showData = true;
        } else if (g_loggedInUser?.type === 'super_admin' && g_subscriber_admins[key].type === 'admin') {
            showData = true;
        } else if (g_loggedInUser?.type === 'admin' && g_subscriber_admins[key].type !== 'super_admin') {
            showData = true;
        }

        if (showData) {

            let actions = `<button type="button" class="btn btn-primary edit-subscriber"><i class="fa fa-edit"></i></button>
                                    <button type="button" class="btn btn-danger delete-subscriber"><i class="fa fa-trash"></i></button>`;
            let element = $(`<tr>
                                <td>${g_subscriber_admins[key].name}</td>
                                <td class="text-center">${g_subscriber_admins[key].email}</td>
                                <td class="text-center">${g_subscriber_admins[key].phone}</td>
                                <td class="text-center">${g_subscriber_admins[key].password}</td>
                                <td class="text-center">${g_subscriber_admins[key].status ? 'ACTIVE' : 'IN-ACTIVE'}</td>
                                <td class="text-center">
                                    ${g_loggedInUser.addAdminPermission ? actions : ''}
                                </td>
                            </tr>`);

            if (g_loggedInUser.addAdminPermission) {
                element.find('.edit-subscriber').bind('click', editSubscriber.bind(this, g_subscriber_admins[key]));
                element.find('.delete-subscriber').bind('click', deleteSubscriber.bind(this, g_subscriber_admins[key]));
            }

            subscriberAdminsElements.push(element);
        }
    });

    if (subscriberAdminsElements.length === 0) {
        $('#subscriberAdminTable tbody').empty().html(`<tr><th colspan="6" class="text-center">No data found.</th></tr>`);
    } else {
        $('#subscriberAdminTable tbody').empty().html(subscriberAdminsElements);
    }
};

const editSubscriber = (adminData) => {
    $('#createSubscriberAdminModal #adminId').val(adminData.saId || '');
    $('#createSubscriberAdminModal #adminName').val(adminData.name || '');
    $('#createSubscriberAdminModal #adminEmail').val(adminData.email || '');
    $('#createSubscriberAdminModal #adminPassword').val(adminData.password || '');
    $('#createSubscriberAdminModal #adminPhone').val(adminData.phone || '');
    $('#createSubscriberAdminModal #adminStatus').val(adminData.status ? 'Active' : 'InActive');
    $('#createSubscriberAdminModal #manageSubscriberAdmin').prop('checked', adminData.addAdminPermission);

    $('#createSubscriberAdminModal').modal('show');
};

const deleteSubscriber = (adminData) => {
    bootbox.confirm({
        message: 'Are you sure you want to delete this subscriber admin?',
        centerVertical: true,
        buttons: {
            confirm: {
                label: 'Yes', className: 'btn-success',
            }, cancel: {
                label: 'No', className: 'btn-danger',
            },
        },
        callback: function (result) {
            if (result) {
                RenderSystem.showLoadingDataMessage();
                UserController.deleteSubscriberAdmin(adminData.saId).then((status) => {
                    RenderSystem.hideLoadingDataMessage();
                    if (status) {
                        Aws_AdminSDK.deleteAwsCognitoUser(adminData.email);
                        RenderSystem.showAlertMessage('Subscriber admin deleted successfully.', 'success');
                    } else {
                        RenderSystem.showAlertMessage('Subscriber admin delete failed.', 'danger');
                    }
                });
            }
        }
    });
};

function formatOptionTemplate(opt) {
    if (g_clients[opt.id] === undefined) {
        return $('<span><img src="./images/no-image-found.png" alt="Location" width="40" height="40"><img src="./images/no-image-found.png" alt="Location" width="35" height="35" class="mr-2"> ' + opt.text + '</span>');
    } else {
        let uphoto = g_clients[opt.id]?.photo ? checkImageExistOrNot(g_clients[opt.id]?.photo) : './images/no-image-found.png';
        let bLogo = g_clients[opt.id]?.brandLogo ? checkImageExistOrNot(g_clients[opt.id]?.brandLogo) : './images/no-image-found.png';
        return $('<span><img src="' + uphoto + '" alt="Location" width="40" height="40"><img src="' + bLogo + '" alt="Location" width="35" height="35" class="mr-2"> ' + opt.text + '</span>');
    }
}

function formatOptionSelectionTemplate(opt) {
    if (g_clients[opt.id] === undefined) {
        return $('<span><img src="./images/no-image-found.png" alt="Location" width="20" height="20"><img src="./images/no-image-found.png" alt="Location" width="15" height="15" class="mr-2"> ' + opt.text + '</span>');
    } else {
        let uphoto = g_clients[opt.id]?.photo ? checkImageExistOrNot(g_clients[opt.id]?.photo) : './images/no-image-found.png';
        let bLogo = g_clients[opt.id]?.brandLogo ? checkImageExistOrNot(g_clients[opt.id]?.brandLogo) : './images/no-image-found.png';
        return $('<span><img src="' + uphoto + '" alt="Location" width="20" height="20"><img src="' + bLogo + '" alt="Location" width="15" height="15" class="mr-2"> ' + opt.text + '</span>');
    }
}

/**
 * Show client UID store details in modal
 * Show invoices details in modal
 * @returns {Promise<void>}
 */
async function showClientStoreData(clientUID = null) {

    RenderSystem.showLoadingDataMessage('Please wait a moment...');

    try {

        if (clientUID) {

            setClientUid(clientUID);
            login(clientUID, USER_ROLE.ADMIN.id, USER_ROLE.ADMIN.id, 'true');

            let subscriptionBenefit = 0;
            let unpaidInvoice = 0, paidInvoice = 0, reviewInvoice = 0, locations = {}, locationPrintingSettings = {}, clientData = {};

            unpaidInvoice = await AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsInvoices, [
                {fieldName: 'clientUid', conditionType: 'eq', fieldValue: clientUID},
                {fieldName: 'paymentType', conditionType: 'eq', fieldValue: 'unpaid'},
            ]).then((querySnapshot) => {
                return querySnapshot.length;
            });

            paidInvoice = await AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsInvoices, [
                {fieldName: 'clientUid', conditionType: 'eq', fieldValue: clientUID},
                {fieldName: 'paymentType', conditionType: 'eq', fieldValue: 'paid'},
            ]).then((querySnapshot) => {
                if (querySnapshot.length > 0) {
                    querySnapshot.forEach((doc) => {
                        subscriptionBenefit = parseFloat(subscriptionBenefit) + parseFloat(doc?.totalPrice || 0);
                    });
                    return querySnapshot.length;
                } else {
                    return 0;
                }
            });

            reviewInvoice = await AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsInvoices, [
                {fieldName: 'clientUid', conditionType: 'eq', fieldValue: clientUID},
                {fieldName: 'paymentType', conditionType: 'eq', fieldValue: 'check'},
            ]).then((querySnapshot) => {
                return querySnapshot.length;
            });

            locations = await LocationController.getLocations(clientUID);

            locationPrintingSettings = await AmplifyDbController.getDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsPrintOptions, [
                {fieldName: 'clientUid', conditionType: 'eq', fieldValue: clientUID},
            ]).then((querySnapshot) => {
                const list = {};
                querySnapshot.forEach((doc) => {
                    list[doc.lid] = doc;
                });
                return list;
            });

            clientData = await ClientController.getClientById(clientUID).then((data) => JSON.parse(JSON.stringify(data)));
            clientData.password = await ClientController.getClientPasswordById(clientUID);

            let locationElements = [];

            let clientInfoElements = (`
<p class="card-text mb-0">${clientData.name ? clientData.name : ''} ${clientData.phone ? clientData.phone : ''}</p>
<p class="card-text mb-0">${clientData.createdAt ? moment(clientData.createdAt).format('YYYY-MM-DD HH:mm:ss') : ''}</p>
<p class="card-text mb-0">${clientData.companyName ? clientData.companyName : ''}</p>
<p class="card-text mb-2">${clientData.companyAddress ? clientData.companyAddress : ''} ${clientData.cif ? clientData.cif : ''}</p>
<p class="card-text mb-0">Account created at: ${clientData.createdAt ? moment(moment(clientData.createdAt).format('YYYY-MM-DD HH:mm:ss')).fromNow() : ''}</p>
<p class="card-text mb-0">${clientData.adminAppLastLogin ? 'Last login ADMIN: ' + moment(clientData.adminAppLastLogin).format('YYYY-MM-DD HH:mm:ss') : ''}</p>
<p class="card-text mb-2">${clientData.posAppLastLogin ? 'Last login POS: ' + moment(clientData.posAppLastLogin).format('YYYY-MM-DD HH:mm:ss') : ''}</p>
<p class="card-text mb-2">${clientData.email ? clientData.email : ''}</p>
<p class="card-text mb-3">${clientData.id ? clientData.id : ''}</p>
<p class="card-text mb-0">Number of unpaid invoices: ${unpaidInvoice}</p>
<p class="card-text mb-0">Number of invoices to review: ${reviewInvoice}</p>
<p class="card-text mb-3">Number of invoices paid: ${paidInvoice}</p>
<p class="card-text mb-0">Subscription benefit: ${parseFloat(subscriptionBenefit).toFixed(2)}</p>
<p class="card-text">Profit marketing campaigns: xxx</p>
`);

            if (Object.entries(locations).length > 0) {
                for (const [key, value] of Object.entries(locations)) {
                    let locationsPhoto = locationPrintingSettings[key] !== undefined ? locationPrintingSettings[key]?.printPhoto : './images/no-image-found.png';
                    // let locationElement = $(`<div class="col-xs-6 col-sm-3 col-md-3 text-center location">
                    //                 <img class="rounded-circle" src="${locationsPhoto ? checkImageExistOrNot(locationsPhoto) : './images/no-image-found.png'}" alt="User Image">
                    //                 ${value.title}
                    //             </div>`);
                    let locationElement = $(`<article id="location-${key}" class="">
                                    <div class="image">
                                        <img src="${locationsPhoto ? checkImageExistOrNot(locationsPhoto) : './images/no-image-found.png'}" alt="${value.title}">
                                    </div>
                                    <div class="title text-center">${value.title}</div>
                                </article>`);
                    locationElements.push(locationElement);
                }
            } else {
                locationElements.push($(`<div class="col text-center">No location found.</div>`));
            }

            await setRatingStar((clientData.ratings ? clientData.ratings : 0), document.querySelectorAll('.reviewStars span'));

            $('#blockOrUnblockUID').attr('data-uid', clientUID).attr('data-account-status', (clientData.accountStatus ? clientData.accountStatus : ''));
            $('#deletedUID_Account').attr('data-uid', clientUID);
            $('.reviewStars').find('span').attr('data-uid', clientUID);
            $('#uphoto').attr('src', clientData.photo ? checkImageExistOrNot(clientData.photo) : './images/no-image-found.png');
            $('#bLogo').attr('src', clientData.brandLogo ? checkImageExistOrNot(clientData.brandLogo) : './images/no-image-found.png');
            $('.client-info').empty().html(clientInfoElements);
            $('.locations-info').empty().html(locationElements);
            generateCustomToken(clientUID, clientData);
        }

    } catch (e) {
        console.log('showClientStoreData Error: ', e.message);
        RenderSystem.hideLoadingDataMessage();
    }

}

function generateCustomToken(uId, clientData) {
    let response = AmplifyAuthController.encodeJWTPayload({
        uid: uId,
        email: clientData?.email || '',
        password: clientData?.password || '',
    });
    // console.log('token', response);
    // console.log('decodeToken', AmplifyAuthController.decodeJWTPayload(response));
    if (response && clientData?.email && clientData?.password) {
        showPopup(uId, response, clientData);
    } else {
        RenderSystem.hideLoadingDataMessage();
        RenderSystem.showAlertMessage('Invalid operation performed!', 'danger');
    }
}

function showPopup(clientUID, token, clientData) {

    $('.modal').off('show.bs.modal');
    $('.modal').off('shown.bs.modal');
    $('.modal').on('show.bs.modal', function (event) {
        var idx = $('.modal:visible').length;
        $(this).css('z-index', 1040 + (10 * idx));
    });
    $('.modal').on('shown.bs.modal', function (event) {
        var idx = ($('.modal:visible').length) - 1; // raise backdrop after animation.
        $('.modal-backdrop').not('.stacked').css('z-index', 1039 + (10 * idx));
        $('.modal-backdrop').not('.stacked').addClass('stacked');
    });

    let deleteUidAccountElm = $('#deletedUID_Account'), blockOrUnblockUidAccountElm = $('#blockOrUnblockUID');

    // iframeLiveURL = `https://www.app.salepointpro.com/admin/index.html?subscriberAdmin=true&cuid=${clientUID}&token=${token}&type=admin`;
    let iframeURL = `../admin/index.html?subscriberAdmin=true&cuid=${clientUID}&token=${encodeURIComponent(token)}&type=admin`;
    if (SALEPRO_APP_ENVIRONMENT === 'salepro-subscriber') {
        // iframeURL = `../salepro/admin/index.html?subscriberAdmin=true&cuid=${clientUID}&token=${encodeURIComponent(token)}&type=admin`;
        iframeURL = `/salepro/admin/sections/main-section/reports.html?subscriberAdmin=true&cuid=${clientUID}&token=${encodeURIComponent(token)}&type=admin`;
    }

    document.getElementById('docView').src = iframeURL;

    setTimeout(() => {

        document.getElementById('docView').src = iframeURL;

        setTimeout(() => {
            try {
                let docViewElement = document.getElementById('docView');
                if (docViewElement) {
                    docViewElement.style = 'height:100%;';
                }
                if (document.getElementById('docView').contentWindow.document.querySelector('html')) {
                    document.getElementById('docView').contentWindow.document.querySelector('html').style = 'height:100%;';
                }
                // if (document.getElementById('docView').contentWindow.document.querySelector('body')) {
                //   document.getElementById('docView').contentWindow.document.querySelector('body').style = 'zoom:100%;transform: scaleX(1);height:100%;';
                // }
                // if (document.getElementById('docView').contentWindow.document.querySelector('main')) {
                //   document.getElementById('docView').contentWindow.document.querySelector('main').style = 'height:100%;';
                // }
            } catch (e) {
                console.log('Admin section iframe error: ', e);
            }

            if (clientData.accountStatus === 'blocked') {
                $('div.store-account-details').block({
                    message: '<h1>This client account is blocked. You can not perform any action.</h1>',
                    css: {border: '3px solid #a00'},
                });
                $('#showInvoiceModal').attr('disabled', true);
            }

            RenderSystem.hideLoadingDataMessage();

            $('#clientUIDModal').modal('show');

        }, 1200);
    }, 500);

    // deleteUidAccountElm.off('click');
    // deleteUidAccountElm.on('click', function () {
    //     let storeOwnerUID = $(this).data('uid');
    //
    //     if (clientUID !== storeOwnerUID.toString()) {
    //         RenderSystem.showAlertMessage('Invalid operation performed!', 'danger');
    //         return false;
    //     }
    //     deleteClientAccount(storeOwnerUID);
    // });

    // blockOrUnblockUidAccountElm.off('click');
    // blockOrUnblockUidAccountElm.on('click', function () {
    //     let buttonElement = $(this);
    //     let status = $(this).data('account-status');
    //     let storeOwnerUID = $(this).data('uid');
    //     accountBlockOrUnblock(buttonElement, status, clientUID);
    // });
}

const deleteClientAccount = (storeOwnerUID) => {
    bootbox.confirm({
        message: 'Are you sure you want to delete this UID account?', centerVertical: true, buttons: {
            confirm: {
                label: 'Yes', className: 'btn-success',
            }, cancel: {
                label: 'No', className: 'btn-danger',
            },
        }, callback: function (result) {
            if (result) {
                let subscriberAdminCredentials = UserController.getSubscriberAdminData();
                if (subscriberAdminCredentials) {
                    UserController.reAuthenticateUserByPassword(subscriberAdminCredentials.email, async (status) => {
                        if (status) {
                            let clientAccountAllUsers = await SalePRO_ClientsController.getClientAccountAllUsers(storeOwnerUID);
                            console.log(clientAccountAllUsers);
                            if (Object.keys(clientAccountAllUsers).length > 0) {
                                await customAsyncForEach(Object.values(clientAccountAllUsers), async (userEmail) => {
                                    await Aws_AdminSDK.deleteAwsCognitoUser(userEmail);
                                });
                                await SalePRO_ClientsController.deleteClientUsers(Object.keys(clientAccountAllUsers));
                            }
                            let response = await SalePRO_ClientsController.deleteClientData(storeOwnerUID);
                            if (response) {
                                $('#clientUIDModal').modal('hide');
                                RenderSystem.hideLoadingDataMessage();
                                RenderSystem.showAlertMessage('The account deleted successfully.');
                                await renderClients();
                            } else {
                                $('#clientUIDModal').modal('hide');
                                RenderSystem.hideLoadingDataMessage();
                                RenderSystem.showAlertMessage('Invalid operation performed! Please try again later.', 'danger');
                                await renderClients();
                            }
                        } else {
                            RenderSystem.hideLoadingDataMessage();
                        }
                    });
                }
            }
        },
    });
};

const accountBlockOrUnblock = (buttonElement, status, storeOwnerUID) => {
    if (status === 'blocked') {
        bootbox.confirm({
            message: 'Are you sure you want to unblock this UID account?', centerVertical: true, buttons: {
                confirm: {
                    label: 'Yes', className: 'btn-success',
                }, cancel: {
                    label: 'No', className: 'btn-danger',
                },
            }, callback: function (result) {
                if (result) {
                    let subscriberAdminCredentials = UserController.getSubscriberAdminData();
                    if (subscriberAdminCredentials) {
                        UserController.reAuthenticateUserByPassword(subscriberAdminCredentials.email, async (status) => {
                            if (status) {
                                let response = await SalePRO_ClientsController.saveClientData(storeOwnerUID, {accountStatus: 'unblocked'});
                                if (response) {
                                    RenderSystem.hideLoadingDataMessage();
                                    $(buttonElement).data('account-status', 'unblocked');
                                    if ($('div.store-account-details').data('blockUI.isBlocked')) {
                                        $('div.store-account-details').unblock();
                                    }
                                    RenderSystem.showAlertMessage('This account unblocked successfully.');
                                    await renderClients();
                                } else {
                                    RenderSystem.hideLoadingDataMessage();
                                    RenderSystem.showAlertMessage('Invalid operation performed! Please try again later.', 'danger');
                                }
                            } else {
                                RenderSystem.hideLoadingDataMessage();
                            }
                        });
                    } else {
                        RenderSystem.showAlertMessage('Invalid operation performed! Please try again later.', 'danger');
                    }
                }
            },
        });
    } else {
        if (status === '' || status === null || status === undefined || status === 'unblocked') {
            bootbox.confirm({
                message: 'Are you sure you want to block this UID account?', centerVertical: true, buttons: {
                    confirm: {
                        label: 'Yes', className: 'btn-success',
                    }, cancel: {
                        label: 'No', className: 'btn-danger',
                    },
                }, callback: function (result) {
                    if (result) {
                        let subscriberAdminCredentials = UserController.getSubscriberAdminData();
                        if (subscriberAdminCredentials) {
                            UserController.reAuthenticateUserByPassword(subscriberAdminCredentials.email, async (status) => {
                                if (status) {
                                    let response = await SalePRO_ClientsController.saveClientData(storeOwnerUID, {accountStatus: 'blocked'});
                                    if (response) {
                                        RenderSystem.hideLoadingDataMessage();
                                        $(buttonElement).data('account-status', 'blocked');
                                        $('div.store-account-details').block({
                                            message: '<h1>This client account is blocked. You can not perform any action.</h1>',
                                            css: {border: '3px solid #a00'},
                                        });
                                        RenderSystem.showAlertMessage('This account blocked successfully.');
                                        await renderClients();
                                    } else {
                                        RenderSystem.hideLoadingDataMessage();
                                        RenderSystem.showAlertMessage('Invalid operation performed! Please try again later.', 'danger');
                                    }
                                } else {
                                    RenderSystem.hideLoadingDataMessage();
                                }
                            });
                        } else {
                            RenderSystem.showAlertMessage('Invalid operation performed! Please try again later.', 'danger');
                        }
                    }
                },
            });
        }
    }
};
