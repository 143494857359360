import {AWS_DATASTORE_SCHEMA_NAMES} from "../constants.js";
import {isDefined} from "../utils.js";
import * as AmplifyDbController from "../../amplify-common/datastore-db.js";

export const getSaleProSettings = async () => {
    return await AmplifyDbController.getAllDataFromAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProConfigs).then((querySnapshot) => {
        const list = {};
        querySnapshot.forEach((doc) => {
            list[doc.key] = doc?.value || '';
        });
        return list;
    });
};

export const addSetting = async (settingsKey, settingsKeyValue) => {
    let settings = await getSaleProSettings();
    if(isDefined(settings, settingsKey)) {
        return await AmplifyDbController.updateDataInAwsDataStoreByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProConfigs, 'eq', 'key', settingsKey, {key: settingsKey, value: settingsKeyValue.toString()});
    } else {
        return await AmplifyDbController.insertDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProConfigs, {key: settingsKey, value: settingsKeyValue.toString()});
    }
};
