import {AWS_DATASTORE_SCHEMA_NAMES} from "../constants.js";
import * as AmplifyDbController from "../../amplify-common/datastore-db.js";
import {asyncForEach} from "../utils.js";

export const getClientAccountAllUsers = async (clientUID) => {
    return await AmplifyDbController.getAllDataFromAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers).then((querySnapshot) => {
        let list = {};
        querySnapshot.forEach((doc) => {
            if (doc?.clientUid === clientUID || doc?.clientUIDs?.includes(clientUID)) {
                if(list[doc.uid] === undefined) {
                    list[doc.uid] = doc.email;
                }
            }
        });
        return list;
    });
};

export const listenClients = (render) => {
    AmplifyDbController.listenAllDataFromCollection(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, render, 'createdAt', 'desc', 'clientUid');
};

export const getClients = async (searchString = '') => {
    return AmplifyDbController.getAllDataFromAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, 'createdAt', 'desc').then((querySnapshot) => {
        const list = {};
        querySnapshot.forEach((doc) => {
            if (searchString) {
                if (typeof doc.name !== 'undefined') {
                    let uName = doc.name;
                    let cName = typeof doc.companyName !== 'undefined' ? doc.companyName : '';
                    let result1 = uName.match(new RegExp(searchString, 'i'));
                    let result2 = cName.match(new RegExp(searchString, 'i'));
                    if (result1 || result2) {
                        list[doc.clientUid] = doc;
                    }
                }
            } else {
                list[doc.clientUid] = doc;
            }
        });
        return list;
    }).catch(() => {
        return {};
    });
};

export const getClientById = (clientUID) => {
    return AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, clientUID);
};

export const getClientPasswordById = (clientUID) => {
    return AmplifyDbController.getDataFromAwsDataStoreById(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, clientUID).then((data) => data?.password || null);
};

export const saveClientData = async (clientUID, dataObj = {}) => {
    try {
        if (clientUID !== undefined) {
            await AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, clientUID, dataObj);
        } else {
            await AmplifyDbController.insertDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, dataObj);
        }
        return true;
    } catch (e) {
        return false;
    }
};

export const deleteClientUsers = async (clientUsers) => {
    await asyncForEach(clientUsers, async function (uid) {
        await AmplifyDbController.deleteDataFromAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, uid);
        await AmplifyDbController.deleteDataFromAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProAppCustomers, uid);
    });
};

export const deleteClientData = async (clientUID) => {
    let conditions = [{fieldName: 'clientUid', conditionType: 'eq', fieldValue: clientUID},];
    return AmplifyDbController.deleteDataFromAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClients, clientUID).then((status) => {
        if (status) {
            AmplifyDbController.deleteDataFromAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsKicker, clientUID);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProUsers, [{fieldName: 'adminUID', conditionType: 'eq', fieldValue: clientUID},]);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsUsers, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSubscriptions, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsLocations, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsLocationsCounts, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsRegions, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsPaymentMethods, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsStorages, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsStoragesCounts, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsOrders, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSaleFeedbacks, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsShipments, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsProducts, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsServices, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSuppliers, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSupplierOrders, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsDamages, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsTesters, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsTransfers, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsTimelineNote, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsProductCategories, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsProductAttributes, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsProductAttributes, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProSuggestions, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsWebsite, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsTaxRules, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsSaleOptions, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsPrintOptions, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsOpenDay, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsObligations, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsLayaway, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsInventory, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsEmployeeCompetition, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsCountingOptions, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsCompetition, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsCloseDay, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsSettingsAppointmentOptions, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsPosEmpDashboard, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsPayrollType, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmpNotificationPermissions, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsLayaways, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsInvoices, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsGiftCards, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsExpenses, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsExpenseCategories, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmployeeDocuments, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmployeeActivity, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmployeeType, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmployeeSchedule, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsCustomerLoyality, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsCheapMessages, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsBookmark, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsAppointment, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsAppNotifications, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsAppMessages, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsAdditions, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsActivityLogs, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsAppMails, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsFeedbacks, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsEmployeeSalary, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsQuestionsFeedback, conditions);
            AmplifyDbController.deleteMultipleDataFromAwsDataStoreByConditions(AWS_DATASTORE_SCHEMA_NAMES.SaleProClientsAppStoreNotifications, conditions);
        }
        return status;
    });
};
