import * as SaleProConfigController from '../database/db/salepro-config-db.js';
import * as RenderSystem from '../render/render-system.js';
import {isEmptyObject} from "../database/utils.js";

export async function renderSaleProConfigs() {
    try {
        SaleProConfigController.getSaleProSettings().then((configs) => {
            if (isEmptyObject(configs) === false) {
                for (const [key, value] of Object.entries(configs)) {
                    let inputKey = `#${key}`;
                    if ($(inputKey).attr('type') === 'checkbox') {
                        $(inputKey).prop('checked', (value === true || value === 'true'));
                    } else {
                        $(inputKey).val(value);
                    }
                }
            }
        });
    } catch (e) {
        console.log('renderSaleProConfigs Error: ', e.message);
    }
}

export function applyConfigEvents() {

    let $enableSubscription = $('#enableSubscription'), $freePeriod = $('#freePeriod'), $priceForOnlineStore = $('#priceForOnlineStore'),
        $priceForStorage = $('#priceForStorage'), $priceForLocation = $('#priceForLocation'), $vatPriceForLocation = $('#vatPriceForLocation'),
        $subscriptionPrice = $('#subscriptionPrice'), $tempClosedStorePrice = $('#tempClosedStorePrice'), $accountCreationEmails = $('#accountCreationEmails');

    $freePeriod.numeric({allowMinus: false, min: 1});
    $priceForOnlineStore.numeric({allowMinus: false, min: 1});
    $priceForLocation.numeric({allowMinus: false, min: 1});
    $priceForStorage.numeric({allowMinus: false, min: 1});
    $vatPriceForLocation.numeric({allowMinus: false, min: 1});
    $subscriptionPrice.numeric({allowMinus: false, min: 1});
    $tempClosedStorePrice.numeric({allowMinus: false, min: 1});

    $enableSubscription.on('change', function () {
        SaleProConfigController.addSetting('enableSubscription', $enableSubscription.is(':checked')).then(() => {
            if ($enableSubscription.is(':checked')) {
                RenderSystem.showAlertMessage('Subscription for all stores enabled.');
            } else {
                RenderSystem.showAlertMessage('Subscription for all stores disabled.');
            }
        }).catch((error) => {
            RenderSystem.showAlertMessage(error.message, 'danger');
        });
    });
    $freePeriod.on('change', function () {
        SaleProConfigController.addSetting('freePeriod', Number($(this).val() || '')).then(() => {
            RenderSystem.showAlertMessage('Free period days saved.');
        }).catch((error) => {
            RenderSystem.showAlertMessage(error.message, 'danger');
        });
    });
    $priceForOnlineStore.on('change', function () {
        SaleProConfigController.addSetting('priceForOnlineStore', $(this).val()).then(() => {
            RenderSystem.showAlertMessage('Price for online store saved.');
        }).catch((error) => {
            RenderSystem.showAlertMessage(error.message, 'danger');
        });
    });
    $priceForLocation.on('change', function () {
        SaleProConfigController.addSetting('priceForLocation', $(this).val()).then(() => {
            RenderSystem.showAlertMessage('Price for location saved.');
        }).catch((error) => {
            RenderSystem.showAlertMessage(error.message, 'danger');
        });
    });
    $priceForStorage.on('change', function () {
        SaleProConfigController.addSetting('priceForStorage', $(this).val()).then(() => {
            RenderSystem.showAlertMessage('Price for storage saved.');
        }).catch((error) => {
            RenderSystem.showAlertMessage(error.message, 'danger');
        });
    });
    $vatPriceForLocation.on('change', function () {
        SaleProConfigController.addSetting('vatPriceForLocation', $(this).val()).then(() => {
            RenderSystem.showAlertMessage('VAT price for location saved.');
        }).catch((error) => {
            RenderSystem.showAlertMessage(error.message, 'danger');
        });
    });
    $subscriptionPrice.on('change', function () {
        SaleProConfigController.addSetting('subscriptionPrice', $(this).val()).then(() => {
            RenderSystem.showAlertMessage('Store subscription price saved.');
        }).catch((error) => {
            RenderSystem.showAlertMessage(error.message, 'danger');
        });
    });
    $tempClosedStorePrice.on('change', function () {
        SaleProConfigController.addSetting('tempClosedStorePrice', $(this).val()).then(() => {
            RenderSystem.showAlertMessage('Temporarily closed Store price saved.');
        }).catch((error) => {
            RenderSystem.showAlertMessage(error.message, 'danger');
        });
    });
    $accountCreationEmails.on('change', function () {
        SaleProConfigController.addSetting('accountCreationEmails', $(this).val()).then(() => {
            RenderSystem.showAlertMessage('Email saved.');
        }).catch((error) => {
            RenderSystem.showAlertMessage(error.message, 'danger');
        });
    });
}
