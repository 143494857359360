import {AWS_DATASTORE_SCHEMA_NAMES} from "../constants.js";
import {getClientUid} from "../localStorage.js";

import * as AmplifyDbController from "../../amplify-common/datastore-db.js";

export const listenAllSuggestions = (render) => {
    //orderBy('read', 'asc') //@TODO
    AmplifyDbController.listenAllDataFromCollection(AWS_DATASTORE_SCHEMA_NAMES.SaleProSuggestions, render, 'date', 'desc');
};

export const listenSuggestionsByClientUid = (clientUid, getList, render) => {
    AmplifyDbController.listenDataFromCollectionByCondition(AWS_DATASTORE_SCHEMA_NAMES.SaleProSuggestions, render, [
        {fieldName: 'clientUid', conditionType: 'eq', fieldValue: getClientUid()}
    ],'date', 'desc');
};

export const updateSuggestion = (id, data) => {
    return AmplifyDbController.updateDataInAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProSuggestions, id, data);
};

export const deleteSuggestion = (id) => {
    return AmplifyDbController.deleteDataFromAwsDataStore(AWS_DATASTORE_SCHEMA_NAMES.SaleProSuggestions, id);
};