import * as BuildConfigs from '../../../build-config.js';

export const AWS_DATASTORE_SCHEMA_NAMES = {
    SaleProUsers: 'SaleProUsers',
    SubscriberAdmin: 'SubscriberAdmin',
    SaleProSmsPackage: 'SaleProSmsPackage',
    SaleProQueues: 'SaleProQueues',
    SaleProBackupSettings: 'SaleProBackupSettings',
    SaleProAppCustomers: 'SaleProAppCustomers',
    MessageBirdChannels: 'MessageBirdChannels',
    CrmUsers: 'CrmUsers',
    CrmNotifications: 'CrmNotifications',
    SaleProTimezone: 'SaleProTimezone',
    SaleProSuggestions: 'SaleProSuggestions',
    SaleProConfigs: 'SaleProConfigs',
    SaleProClients: 'SaleProClients',
    SaleProClientsAppMails: 'SaleProClientsAppMails', // new
    SaleProClientsKicker: 'SaleProClientsKicker',
    SaleProClientsActivityLogs: 'SaleProClientsActivityLogs',
    SaleProClientsAdditions: 'SaleProClientsAdditions',
    SaleProClientsAppMessages: 'SaleProClientsAppMessages',
    SaleProClientsAppNotifications: 'SaleProClientsAppNotifications',
    SaleProClientsAppStoreNotifications: 'SaleProClientsAppStoreNotifications',
    SaleProClientsAppointment: 'SaleProClientsAppointment',
    SaleProClientsBookmark: 'SaleProClientsBookmark',
    SaleProClientsCheapMessages: 'SaleProClientsCheapMessages',
    SaleProClientsCustomerLoyality: 'SaleProClientsCustomerLoyality',
    SaleProClientsDamages: 'SaleProClientsDamages',
    SaleProClientsEmployeeActivity: 'SaleProClientsEmployeeActivity',
    SaleProClientsEmployeeDocuments: 'SaleProClientsEmployeeDocuments',
    SaleProClientsEmployeeSchedule: 'SaleProClientsEmployeeSchedule',
    SaleProClientsEmployeeSalary: 'SaleProClientsEmployeeSalary',
    SaleProClientsEmployeeType: 'SaleProClientsEmployeeType',
    SaleProClientsExpenses: 'SaleProClientsExpenses',
    SaleProClientsExpenseCategories: 'SaleProClientsExpenseCategories',
    SaleProClientsGiftCards: 'SaleProClientsGiftCards',
    SaleProClientsInvoices: 'SaleProClientsInvoices',
    SaleProClientsLayaways: 'SaleProClientsLayaways',
    SaleProClientsLocations: 'SaleProClientsLocations',
    SaleProClientsLocationsCounts: 'SaleProClientsLocationsCounts',
    SaleProClientsEmpNotificationPermissions: 'SaleProClientsEmpNotificationPermissions',
    SaleProClientsOrders: 'SaleProClientsOrders',
    SaleProClientsPaymentMethods: 'SaleProClientsPaymentMethods',
    SaleProClientsPayrollType: 'SaleProClientsPayrollType',
    SaleProClientsPosEmpDashboard: 'SaleProClientsPosEmpDashboard',
    SaleProClientsProductAttributes: 'SaleProClientsProductAttributes',
    SaleProClientsProductCategories: 'SaleProClientsProductCategories',
    SaleProClientsProducts: 'SaleProClientsProducts',
    SaleProClientsRegions: 'SaleProClientsRegions',
    SaleProClientsFeedbacks: 'SaleProClientsFeedbacks', //new
    SaleProClientsQuestionsFeedback: 'SaleProClientsQuestionsFeedback', //new
    SaleProClientsSaleFeedbacks: 'SaleProClientsSaleFeedbacks',
    SaleProClientsServices: 'SaleProClientsServices',
    SaleProClientsSubscriptions: 'SaleProClientsSubscriptions',
    SaleProClientsUsers: 'SaleProClientsUsers',
    SaleProClientsTransfers: 'SaleProClientsTransfers',
    SaleProClientsStores: 'SaleProClientsStores',
    SaleProClientsStorages: 'SaleProClientsStorages',
    SaleProClientsStoragesCounts: 'SaleProClientsStoragesCounts',
    SaleProClientsSettingsInventory: 'SaleProClientsSettingsInventory',
    SaleProClientsWebsite: 'SaleProClientsWebsite',
    SaleProClientsTimelineNote: 'SaleProClientsTimelineNote',
    SaleProClientsTesters: 'SaleProClientsTesters',
    SaleProClientsSuppliers: 'SaleProClientsSuppliers',
    SaleProClientsSupplierOrders: 'SaleProClientsSupplierOrders',
    SaleProClientsSkyAnalysis: 'SaleProClientsSkyAnalysis',
    SaleProClientsShipments: 'SaleProClientsShipments',
    SaleProClientsSettingsTaxRules: 'SaleProClientsSettingsTaxRules',
    SaleProClientsSettingsSaleOptions: 'SaleProClientsSettingsSaleOptions',
    SaleProClientsSettingsPrintOptions: 'SaleProClientsSettingsPrintOptions',
    SaleProClientsSettingsOpenDay: 'SaleProClientsSettingsOpenDay',
    SaleProClientsSettingsObligations: 'SaleProClientsSettingsObligations',
    SaleProClientsSettingsLayaway: 'SaleProClientsSettingsLayaway',
    SaleProClientsSettingsEmployeeCompetition: 'SaleProClientsSettingsEmployeeCompetition',
    SaleProClientsSettingsCountingOptions: 'SaleProClientsSettingsCountingOptions',
    SaleProClientsSettingsCompetition: 'SaleProClientsSettingsCompetition',
    SaleProClientsSettingsCloseDay: 'SaleProClientsSettingsCloseDay',
    SaleProClientsSettingsAppointmentOptions: 'SaleProClientsSettingsAppointmentOptions',
    SaleProClientsTaskCategories: 'SaleProClientsTaskCategories',
    SaleProClientsTasks: 'SaleProClientsTasks',
    SaleProImports: 'SaleProImports',

    SaleProClientsMessages: 'SaleProClientsMessages', // need to check usage
    SaleProDocuments: 'SaleProDocuments',
    SaleProClientsOnlineStoreSettings: 'SaleProClientsOnlineStoreSettings',
    SaleProClientsOnlineStoreNewsletterSubscriber: 'SaleProClientsOnlineStoreNewsletterSubscriber',
    SaleProClientsStoreConfigs: 'SaleProClientsStoreConfigs',
    SaleProClientsStoreSkyAnalysis: 'SaleProClientsStoreSkyAnalysis',
    SaleProClientsStoreSkinAnalysis: 'SaleProClientsStoreSkinAnalysis',
    SaleProClientsStoreWebOnline: 'SaleProClientsStoreWebOnline',
    SaleProClientsStoreTranslate: 'SaleProClientsStoreTranslate',
    SaleProClientsStoreOnlineStoreTemplate: 'SaleProClientsStoreOnlineStoreTemplate',
};

export const AWS_SYNC_SCHEMA_NAMES = [
    {"schema": "SaleProClients", "all": true, "generalLogin": true, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsKicker", "all": true, "generalLogin": true, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsUsers", "all": true, "generalLogin": true, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProUsers", "all": true, "generalLogin": true, "ticket": true, "myAccount": true, "conditionField": "adminUID", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProAppCustomers", "all": true, "generalLogin": false, "ticket": true, "myAccount": false, "conditionField": "clientUIDs", "conditionType": "contains", "noCondition": false},

    {"schema": "SaleProConfigs", "all": true, "generalLogin": true, "ticket": true, "myAccount": true, "conditionField": "id", "conditionType": "eq", "noCondition": true},

    {"schema": "SaleProClientsActivityLogs", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsAdditions", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsAppMails", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsAppMessages", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsAppNotifications", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsAppointment", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsAppStoreNotifications", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsBookmark", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsCheapMessages", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsCustomerLoyality", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsDamages", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsEmployeeActivity", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsEmployeeDocuments", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsEmployeeSalary", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsEmployeeSchedule", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsEmployeeType", "all": true, "generalLogin": true, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsEmpNotificationPermissions", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsExpenseCategories", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsExpenses", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsFeedbacks", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsGiftCards", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsInvoices", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsLayaways", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsLocations", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsLocationsCounts", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsOnlineStoreSettings", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsOrders", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsPaymentMethods", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsPayrollType", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsPosEmpDashboard", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsProductAttributes", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsProductCategories", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsProducts", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsQuestionsFeedback", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsRegions", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSaleFeedbacks", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsServices", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSettingsAppointmentOptions", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSettingsCloseDay", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSettingsCompetition", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSettingsCountingOptions", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSettingsEmployeeCompetition", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSettingsInventory", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSettingsLayaway", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSettingsObligations", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSettingsOpenDay", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSettingsPrintOptions", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSettingsSaleOptions", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSettingsTaxRules", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsShipments", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSkyAnalysis", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsStorages", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsStoragesCounts", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsStoreConfigs", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsStoreOnlineStoreTemplate", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsStores", "all": true, "generalLogin": false, "ticket": true, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsStoreSkinAnalysis", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsStoreSkyAnalysis", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsStoreTranslate", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsStoreWebOnline", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSubscriptions", "all": true, "generalLogin": true, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSupplierOrders", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsSuppliers", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsTaskCategories", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsTasks", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsTesters", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsTimelineNote", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsTransfers", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProClientsWebsite", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProDocuments", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProImports", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProQueues", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "storeUID", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProSmsPackage", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "id", "conditionType": "eq", "noCondition": true},

    {"schema": "SaleProSuggestions", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "clientUid", "conditionType": "eq", "noCondition": false},

    {"schema": "SaleProTimezone", "all": true, "generalLogin": false, "ticket": false, "myAccount": true, "conditionField": "id", "conditionType": "eq", "noCondition": true},

    {"schema": "SubscriberAdmin", "all": true, "generalLogin": true, "ticket": false, "myAccount": false, "conditionField": "saId", "conditionType": "eq", "noCondition": true},

    {"schema": "SaleProBackupSettings", "all": false, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "id", "conditionType": "eq", "noCondition": false},

    {"schema": "CrmAppointments", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "id", "conditionType": "eq", "noCondition": true},

    {"schema": "CrmContacts", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "ccid", "conditionType": "eq", "noCondition": true},

    {"schema": "CrmNotifications", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "id", "conditionType": "eq", "noCondition": true},

    {"schema": "CrmUsers", "all": true, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "uid", "conditionType": "eq", "noCondition": true},

    {"schema": "MessageBirdChannels", "all": false, "generalLogin": false, "ticket": false, "myAccount": false, "conditionField": "id", "conditionType": "eq", "noCondition": true},
];

export const AWS_SCHEMA_WISE_PRIMARY_KEYS = {
    "SaleProClients": "clientUid",
    "SaleProClientsKicker": "clientUid",
    "SaleProClientsUsers": "clientUid",
    "SaleProUsers": "adminUID",
    "SaleProAppCustomers": "clientUIDs",
    "SaleProClientsActivityLogs": "clientUid",
    "SaleProClientsAdditions": "clientUid",
    "SaleProClientsAppMails": "clientUid",
    "SaleProClientsAppMessages": "clientUid",
    "SaleProClientsAppNotifications": "clientUid",
    "SaleProClientsAppointment": "clientUid",
    "SaleProClientsAppStoreNotifications": "clientUid",
    "SaleProClientsBookmark": "clientUid",
    "SaleProClientsCheapMessages": "clientUid",
    "SaleProClientsCustomerLoyality": "clientUid",
    "SaleProClientsDamages": "clientUid",
    "SaleProClientsEmployeeActivity": "clientUid",
    "SaleProClientsEmployeeDocuments": "clientUid",
    "SaleProClientsEmployeeSalary": "clientUid",
    "SaleProClientsEmployeeSchedule": "clientUid",
    "SaleProClientsEmployeeType": "clientUid",
    "SaleProClientsEmpNotificationPermissions": "clientUid",
    "SaleProClientsExpenseCategories": "clientUid",
    "SaleProClientsExpenses": "clientUid",
    "SaleProClientsFeedbacks": "clientUid",
    "SaleProClientsGiftCards": "clientUid",
    "SaleProClientsInvoices": "clientUid",
    "SaleProClientsLayaways": "clientUid",
    "SaleProClientsLocations": "clientUid",
    "SaleProClientsLocationsCounts": "clientUid",
    "SaleProClientsOnlineStoreSettings": "clientUid",
    "SaleProClientsOrders": "clientUid",
    "SaleProClientsPaymentMethods": "clientUid",
    "SaleProClientsPayrollType": "clientUid",
    "SaleProClientsPosEmpDashboard": "clientUid",
    "SaleProClientsProductAttributes": "clientUid",
    "SaleProClientsProductCategories": "clientUid",
    "SaleProClientsProducts": "clientUid",
    "SaleProClientsQuestionsFeedback": "clientUid",
    "SaleProClientsRegions": "clientUid",
    "SaleProClientsSaleFeedbacks": "clientUid",
    "SaleProClientsServices": "clientUid",
    "SaleProClientsSettingsAppointmentOptions": "clientUid",
    "SaleProClientsSettingsCloseDay": "clientUid",
    "SaleProClientsSettingsCompetition": "clientUid",
    "SaleProClientsSettingsCountingOptions": "clientUid",
    "SaleProClientsSettingsEmployeeCompetition": "clientUid",
    "SaleProClientsSettingsInventory": "clientUid",
    "SaleProClientsSettingsLayaway": "clientUid",
    "SaleProClientsSettingsObligations": "clientUid",
    "SaleProClientsSettingsOpenDay": "clientUid",
    "SaleProClientsSettingsPrintOptions": "clientUid",
    "SaleProClientsSettingsSaleOptions": "clientUid",
    "SaleProClientsSettingsTaxRules": "clientUid",
    "SaleProClientsShipments": "clientUid",
    "SaleProClientsSkyAnalysis": "clientUid",
    "SaleProClientsStorages": "clientUid",
    "SaleProClientsStoragesCounts": "clientUid",
    "SaleProClientsStoreConfigs": "clientUid",
    "SaleProClientsStoreOnlineStoreTemplate": "clientUid",
    "SaleProClientsStores": "clientUid",
    "SaleProClientsStoreSkinAnalysis": "clientUid",
    "SaleProClientsStoreSkyAnalysis": "clientUid",
    "SaleProClientsStoreTranslate": "clientUid",
    "SaleProClientsStoreWebOnline": "clientUid",
    "SaleProClientsSubscriptions": "clientUid",
    "SaleProClientsSupplierOrders": "clientUid",
    "SaleProClientsSuppliers": "clientUid",
    "SaleProClientsTaskCategories": "clientUid",
    "SaleProClientsTasks": "clientUid",
    "SaleProClientsTesters": "clientUid",
    "SaleProClientsTimelineNote": "clientUid",
    "SaleProClientsTransfers": "clientUid",
    "SaleProClientsWebsite": "clientUid",
    "SaleProDocuments": "clientUid",
    "SaleProImports": "clientUid",
    "SaleProQueues": "storeUID",
    "SaleProSuggestions": "clientUid",
};

export const FIREBASE_URL = "https://firebasestorage.googleapis.com/v0/b/";

export const FIREBASE_FILES = "files";
export const FIREBASE_FILES_USERS = "users";

export const FIREBASE_IMAGES = "images";
export const FIREBASE_IMAGES_USERS = "users";
export const FIREBASE_IMAGES_PRODUCTS = "products";
export const FIREBASE_EMP_DOCUMENTS = "employee-docs";
export const FIREBASE_IMAGES_BOOKMARK = "bookmark";

// NEW DB
export const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyAJtLRQ8MM1GBvmYlNwkJC_MENlY6Qi0XM',
    authDomain: 'salepro-3460c.firebaseapp.com',
    databaseURL: "https://salepro-3460c.firebaseio.com",
    projectId: 'salepro-3460c',
    storageBucket: 'salepro-3460c.appspot.com',
    messagingSenderId: '1014358796353',
    appId: '1:1014358796353:web:21c910d548b34acbe43ec0',
    measurementId: 'G-V0C31F751W',
};

/**
 * Firebase Web Push notification Public KEY
 * @type {string}
 */
export const googleFireBaseVapidPublicKey = 'BHSXVnpwWkvgTptMPLj8GivFKqJdiKTxFSFx2OCSig93JPXj4oQTgKDKcu6tD5JZtfncFyPTU_Env_5WTDn0krQ';

// USE OFFLINE INDEXEDDB PERSISTENCE
export const USE_INDEXEDDB_PERSISTENCE = false;
export const USE_AUTH_PERSISTENCE = true;

//CONSTANTS DB
export const TABLE_MAIN_CLIENTS = "SalePRO-Clients";
export const TABLE_MAIN_CLIENTS_KICKER = "SalePRO-Clients-Kicker";
export const TABLE_ACTIVITY_LOGS = "activityLogs";
export const TABLE_STORE_CONFIG = "storeConfigs";
export const TABLE_SUBSCRIPTIONS = "SalePRO-Clients-Subscriptions";
export const BASIC_COLLECTION = "SalePRO-Clients";
export const TABLE_APP_NOTIFICATIONS = "appNotifications";
export const TABLE_NOTIFICATIONS = "notifications";
export const TABLE_STORE_NOTIFICATIONS = "appStoreNotifications";
export const TABLE_EMPLOYEE_ACTIVITY = "employeeActivity";
export const TABLE_EMPLOYEE_DOCUMENT = "employeeDocuments";
export const CONFIGS_COLLECTION = "SalePRO-Configs";
export const TABLE_SALEPRO_USERS = "SalePRO-Clients-Users";
export const TABLE_APP_CUSTOMERS = "SalePRO-App-Customers";
export const TABLE_MESSAGE_BIRD_EXTENSION = "Message-Bird-Channels";
export const TABLE_MESSAGE_BIRD_QUEUE = "appMessages";
export const TABLE_EMAIL_QUEUE = "appMails";
export const TABLE_USERS = "users";
export const TABLE_EMPTYPES = "employee-type";
export const TABLE_CATEGORIES = "product-categories";
export const TABLE_PRODUCT = "products";
export const TABLE_ATTRIBUTES = "product-attributes";
export const TABLE_SUPPLIERS = "suppliers";
export const TABLE_SUPPLIER_ORDERS = "supplier-orders";
export const TABLE_REGIONS = "regions";
export const TABLE_LOCATIONS = "locations";
export const TABLE_INVENTORY_SERVICES = "inventory-services";
export const TABLE_INVENTORY_SERVICE_CATEGORIES = "inventory-service-categories";
export const TABLE_STORAGES = "storages";
export const TABLE_ORDERS = "orders";
export const TABLE_LAYAWAYS = "layaways";
export const TABLE_DAMAGES = "damages";
export const TABLE_TESTERS = "testers";
export const TABLE_SHIPMENTS = "shipments";
export const TABLE_COUNTS = "counts";
export const TABLE_GIFT_CARDS = "gift-cards";
export const TABLE_TRANSFERS = "transfers";
export const TABLE_BOOKMARK = "bookmark";
export const TABLE_MESSAGES = "messages";
export const TABLE_CHEAP_MESSAGES = "cheap-messages";
export const TABLE_TIMELINE_NOTE = "timeline-note";

export const TABLE_CATEGORY_SERVICES = "categories";
export const TABLE_SERVICES = "services";
export const TABLE_SALE_FEEDBACKS = "sale-feedbacks";
export const TABLE_FEEDBACKS = "feedbacks";
export const TABLE_SUGGESTIONS = "SalePRO-Suggestions";
export const TABLE_Q_FEEDBACKS = "questions-feedback";
export const TABLE_APPOINTMENTS = "appointment";
export const TABLE_SCHEDULE = "employee-schedule";
export const TABLE_CUSTOMER_LOYALITY = "customer-loyality";

export const TABLE_SETTING_OBLIGATIONS = "settingsObligations";
export const TABLE_SETTING_PRINT = "settingsPrintOptions";
export const TABLE_SETTING_OPEN_DAY = "settingsOpenDay";
export const TABLE_SETTING_CLOSE_DAY = "settingsCloseDay";
export const TABLE_SETTING_GENERAL_INFO = "settingsGeneralInfo";
export const TABLE_SETTING_APPOINTMENT_INFO = "settingsAppointmentOptions";
export const TABLE_SETTING_TAX_INFO = "settingsTaxRules";
export const TABLE_SETTING_LAYAWAY = "settingsLayaway";
export const TABLE_SETTING_COUNTING_OPTIONS = "settingsCountingOptions";

export const DB_ACTION_ADD = "added";
export const DB_ACTION_MODIFY = "modified";
export const DB_ACTION_REMOVE = "removed";

export const DEFAULT_DOMAIN = "@salepointpro.com"; //@gmail.com

//CONSTANTS GENERAL

export const MOBILE_RESOLUTION = 767;
export const TABLET_RESOLUTION = 1366;

//CONSTANTS LOCALSTORAGE
export const TOKEN_KEY = "jwt";
export const LOGIN_TYPE_KEY = "loggedinType";
export const LOCATION_KEY = "locationId";
export const SELECTED_LOCATION_KEY = "selectedLocations";
export const CLIENT_UID_KEY = "clientUid";
export const LOCATION_NAME_KEY = "locationName";
export const ADMIN_BLOCK_KEY = "adminAppBlock";
export const POS_BLOCK_KEY = "posAppBlock";
export const SUBSCRIBER_ADMIN = "subscriberAdmin";
export const OPENDAY_KEY = "doneOpenday";
export const INVENTORY_TOKEN_KEY = "ivttokenkey";
export const CART_INFO_KEY = "cartinfo";
export const CUSTOMER2EDIT = "customer2edit";


//CONSTANTS USERS
export const USER_ROLE = {
    ADMIN: {id: 0, page: "appointmentsadmin.html"},
    EMPLOYEE: {id: 1, page: "appointmentsstore.html"},
    CLIENT: {id: 2, page: "appointmentsclient.html"},
};

export const COLOR_STATES = ["yellow", "red", "green", "#39c"];

export const MSG_STATES = [
    "Appointments created",
    "Appointments cancelled",
    'Appointments confirmed',
    "Appointments completed",
];

export const CHEAP_MSG_STATES = {
    pending: 1,
    confirm: 2,
    reject: 3,
    timeout: 4,
    cancel: 5,
    timoutAfter: 120 //second
};

export const MSG_DEFAULT_FIELD = {
    SERVICE: "Assign a service",
    CLIENT: "Assign a client",
    EMPLOYEE: "Assign an employee to perform the service",
    VENDOR: "Which employee sold the service?",
};

export const MSG_DEFAULT_CATEGORY = "Title of the Category";

export const TAX_AMOUNT_PERCT = 21;
export const DISCOUNT_TYPE_PERCT = "perct";
export const DISCOUNT_TYPE_MONEY = "money";

//CONSTANTS CLOCKIN

export const CLOCKIN_STATES = ["clockin", "breakin", "breakout", "clockout"];
export const CLOCKIN_START = "clockin";
export const CLOCKIN_BREAKIN = "breakin";
export const CLOCKIN_BREAKOUT = "breakout";
export const CLOCKIN_END = "clockout";
export const BOOKMARK_EVENTS = {
    clockin: 'CLOCK IN',
    breakin: 'BREAK IN',
    breakout: 'BREAK OUT',
    clockout: 'CLOCK OUT',
};

//CONSTANTS INVENTORY

export const URL_IMAGE_PRODUCT = "images/iconoSale_1iconoSale.png";
export const URL_IMAGE_SUPPLIER = "images/iconoOptions_1iconoOptions.png";
export const URL_IMAGE_LOCATION = "images/Icon-Location.png";
export const URL_IMAGE_STORAGE = "images/iconINVENTARIOcajas.png";

//CONSTANTS INVENTORY LOCATION

export const URL_IMAGE_ADD = "images/iconINVENTARIOcajas.png";
export const URL_IMAGE_TRANSFERS = "images/iconInventarioPosReceive.png";
export const URL_IMAGE_DAMAGES = "images/Damage.png";
export const URL_IMAGE_MERCHANDISE = "images/sendMerchandise.png";
export const URL_IMAGE_DAMAGE = "images/Damage.png";
export const URL_IMAGE_TESTER = "images/Tester.png";

//CONSTANTS STATES ITEMS

export const ITEMS_STATUS = ["Open", "Transit", "Completed"];

//CONSTANTS DAMAGE TYPE

export const DAMAGE_TYPE = ["Damage / Live", "Lost", "Damage / Tester", "Display", "Other", "Live"];

//CONSTANTS COUNT TYPE

export const COUNT_GENERAL = 0;
export const COUNT_OPEN = 1;
export const COUNT_CLOSE = 2;

export const COUNT_TYPE = ["General", "Open", "Close"];

//CONSTANTS MENU

export const SECTIONS = {
    DASHBOARD: "dashboard",
    CUSTOMERS: "customers",
    APPOINTMENTS: "appointment",
    EMPLOYEE: "empDashboard",
    SALES: "sales",
};

export const APP_TYPE = {
    admin: "Admin",
    pos: "Pos",
};

export const IMAGE_RESIZE = "800x600";

export const googleRecaptchaSiteKey = '6LcMxSkeAAAAAHRMWnKDtejWGc0rKr6yXHATEaoc';

export const DEFAULT_AUTH = {
    email: "sploginpermission@mailinator.com",
    password: "Test1234"
};

export const RES_STATUS = {
    SUCCESS: 'success',
    EMAIL_EXIST: 'email_exist',
    EMAIL_INVALID: 'email_invalid',
    NAME_EXIST: 'name_exist',
    USER_NOT_EXIST: 'user_not_exist',
    FAILED: 'failed',
};

export const NOTIFICATION_EVENTS = {
    'clock': ['clockin', 'clockout', 'breakin', 'breakout'],
    'sale': ['sale_above', 'sale_below', 'sale_refund', 'sale_below_confirm', 'discount_custom_deal'],
    'saleShipment': ['sale_new_shipment', 'sale_shipment_sent'],
    'day': ['openday', 'closeday', 'assign_store_pos_login'],
    'inventory': ['new_loses', 'new_transfer_received', 'new_transfer_send'],
};

export const importFileHeaders = {
    product: ['Name', 'Sku', 'Category', 'Supplier', 'Price', 'Product_Type'],
    employee: ['Display_Name', 'Email', 'First_Name', 'Last_Name', 'Phone', 'Active_Status'],
    customer: ['First_Name', 'Last_Name', 'Email', 'Phone'],
    appointments: ['Service_Name', 'Client_Name', 'Client_Email', 'Employee_Name', 'Employee_Email', 'Date', 'Location_Name', 'Comments', 'Status'],
};

export const staticAuthCredentials = {
    email: 'sploginpermission@mailinator.com',
    password: 'sploginpermission'
};

export const NO_IMAGE_FOUND_IMG = 'https://salepointprostorage55517-dev.s3.eu-north-1.amazonaws.com/no-image-found.png';
export const AWS_USER_IMG = 'https://salepointprostorage55517-dev.s3.eu-north-1.amazonaws.com/male-icon-human.jpg';

export const DEFAULT_MAIL_FROM = "mail@kadimasoftware.com";
export const AWS_LAMDA_CLOUD_FUNCTION_API_URL = "https://shcnhnyiubxcldmy53zrsjjwky0ozcep.lambda-url.eu-north-1.on.aws/";
export const FIREBASE_CLOUD_FUNCTION_URL = "https://europe-west1-salepro-3460c.cloudfunctions.net/salePRO_RestAPI/";
export const FIREBASE_VERIFY_AUTH_URL = FIREBASE_CLOUD_FUNCTION_URL + "verifyAuthAdmin";
export const FIREBASE_VERIFY_CAPTCHA_URL = FIREBASE_CLOUD_FUNCTION_URL + "verifyGoogleRecaptcha";
export const FIREBASE_GET_FILE_BLOB_URL = FIREBASE_CLOUD_FUNCTION_URL + "getStorageBlob";
export const FIREBASE_POST_MAILCHIMP_URL = FIREBASE_CLOUD_FUNCTION_URL + "sendMailChimpMail";
export const FIREBASE_POST_LINK_SHORT_URL = FIREBASE_CLOUD_FUNCTION_URL + "cuttlyShortLinks";
export const FIREBASE_GET_SERACH_USERNAME_URL = FIREBASE_CLOUD_FUNCTION_URL + "searchUsernames";

export const CUTTLY_SHORTEN_LINK_API_KEY = "0b69365cc6b67636b3a2eeb1347f92f3e4f31";
export const CUTTLY_SHORTEN_LINK_API_URL = "https://cutt.ly/api/api.php";


export const FS_ONLINE_STORE_BASE_URL = `https://salepointprostorage55517-dev.s3.eu-north-1.amazonaws.com/public/SaleProClients/`;
export const FS_ONLINE_STORE_DEFAULT_URL = `https://salepointprostorage55517-dev.s3.eu-north-1.amazonaws.com/public/default-online-store/`;
export const OPENAI_CHAT_GPT_API_KEY = "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";
export const SALEPRO_DISABLE_DEV_TOOLS = false;
export const SALEPRO_ENVIRONMENT = 'PROD';
export const SALEPRO_VERSION = BuildConfigs.SP_APP_PROD_BUILD_VERSION;
export const SALEPRO_EMPLOYEE_URL_VERSION = BuildConfigs.SP_EMPLOYEE_APP_BUILD_VERSION;
export const SALEPRO_CLIENT_URL_VERSION = BuildConfigs.SP_CLIENT_APP_BUILD_VERSION;
export const SALEPRO_CRM_VERSION = BuildConfigs.SP_CRM_APP_VERSION;
export const SALEPRO_SUBSCRIBER_CONTROL_URL_VERSION = BuildConfigs.SP_SUBSCRIBER_CONTROL_APP_VERSION;
export const KICKER_POINT_VERSION = BuildConfigs.SP_KICKERPOINT_APP_BUILD_VERSION;
export const SALEPRO_TEST_VERSION = BuildConfigs.SP_APP_TEST_BUILD_VERSION;
export const SALEPRO_OWNER_MANAGER_APP_VERSION = BuildConfigs.SP_OWNER_MANAGER_APP_BUILD_VERSION;
export const SALEPRO_LIVE_SITE_URL = 'https://www.app.salepointpro.com/';
export const SALEPRO_KICKERPOINT_LIVE_SITE_URL = 'https://kickerpoint.com/';
export const SALEPRO_ADMIN_LIVE_SITE_URL = 'https://admin.salepointpro.com/';
export const SALEPRO_EMPLOYEE_LIVE_SITE_URL = 'https://employee.salepointpro.com/';
export const SALEPRO_CLIENT_LIVE_SITE_URL = 'https://client.salepointpro.com/';
export const SALEPRO_SUBSCRIBER_LIVE_SITE_URL = 'https://subscriber-salepro.kadimasoftware.com/';
export const SALEPRO_LIVE_SITE_SALE_FEEDBACK_URL = 'https://www.app.salepointpro.com/ticket.html';


/**
 * Multi Framework build configs
 */
export const SALEPRO_APP_ENVIRONMENT = BuildConfigs.SP_APP_BUILD_ENV;
export const SALEPRO_APP_BUILD_FRAMEWORK = BuildConfigs.SP_APP_BUILD_FRAMEWORK;
export const SALEPRO_INSTALL_SERVICE_WORKER = BuildConfigs.SP_INSTALL_SERVICE_WORKER;
export const SALEPRO_SHOW_UPDATES_INFORMATION_LINK = BuildConfigs.SP_SHOW_UPDATES_INFORMATION_LINK;
export const SALEPRO_ADMIN_APP_SHOW_NOTIFICATION_MENU = BuildConfigs.SP_ADMIN_APP_SHOW_NOTIFICATION_MENU;
export const SALEPRO_APPEND_CORDOVA_SCRIPTS = BuildConfigs.SP_APPEND_CORDOVA_SCRIPTS;
