import {DataStore, Predicates, SortDirection, syncExpression} from 'aws-amplify/datastore';
import {Hub} from 'aws-amplify/utils';

import * as DataStoreSchema from '../../../models/index.js';
import * as AppUtilsFunc from '../database/utils.js';
import * as AppConstants from '../database/constants.js';
import * as AmplifyConfigure from "./amplify-configure.js";

window.dataStoreHubInit = false;

/**
 * Available DataStore condition expressions
 * Strings: eq | ne | le | lt | ge | gt | contains | notContains | beginsWith | between
 * Numbers: eq | ne | le | lt | ge | gt | between
 * Lists: contains | notContains
 */

let dataStoreConnSingletonInstance;

/**
 * DataStore Singleton Connection Instance
 * @type {function(*, *, *, *): {getInstance: function(): Promise<dataStoreInstance>}}
 */
const dataStoreConnSingleton = (function (clientUid, sectionName, changeProcess, clearDataStore) {

    class dataStoreInstance {
        constructor(clientUid, sectionName, changeProcess, clearDataStore) {
            this.clientUid = clientUid;
            this.sectionName = sectionName;
            this.changeProcess = changeProcess;
            this.clearDataStore = clearDataStore;
        }

        /**
         * Process all schema names for sync expressions
         */
        async configDataStore() {

            /**
             * Change process for DataStore sync expressions
             */
            if (changeProcess) {

                /**
                 * Configure DataStore with sync expressions
                 */

                if (clientUid) {
                    DataStore.configure({
                        errorHandler: (error) => {
                            console.warn('DataStore.configure syncExpressions error', {error});
                        },
                        syncExpressions: [
                            syncExpression(DataStoreSchema.SaleProClients, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsKicker, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsUsers, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProUsers, data => data.adminUID.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProAppCustomers, data => data.clientUIDs.contains(clientUid)),
                            syncExpression(DataStoreSchema.SaleProConfigs, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsActivityLogs, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsAdditions, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsAppMails, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsAppMessages, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsAppNotifications, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsAppointment, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsAppStoreNotifications, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsBookmark, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsCheapMessages, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsCustomerLoyality, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsDamages, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsEmployeeActivity, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsEmployeeDocuments, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsEmployeeSalary, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsEmployeeSchedule, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsEmployeeType, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsEmpNotificationPermissions, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsExpenseCategories, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsExpenses, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsFeedbacks, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsGiftCards, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsInvoices, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsLayaways, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsLocations, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsLocationsCounts, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsOnlineStoreSettings, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsOrders, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsPaymentMethods, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsPayrollType, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsPosEmpDashboard, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsProductAttributes, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsProductCategories, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsProducts, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsQuestionsFeedback, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsRegions, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSaleFeedbacks, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsServices, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsAppointmentOptions, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsCloseDay, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsCompetition, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsCountingOptions, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsEmployeeCompetition, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsInventory, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsLayaway, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsObligations, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsOpenDay, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsPrintOptions, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsSaleOptions, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsTaxRules, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsShipments, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSkyAnalysis, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsStorages, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsStoragesCounts, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsStoreConfigs, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsStoreOnlineStoreTemplate, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsStores, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsStoreSkinAnalysis, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsStoreSkyAnalysis, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsStoreTranslate, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsStoreWebOnline, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSubscriptions, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSupplierOrders, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsSuppliers, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsTaskCategories, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsTasks, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsTesters, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsTimelineNote, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsTransfers, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProClientsWebsite, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProDocuments, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProImports, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProQueues, data => data.storeUID.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProSmsPackage, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProSuggestions, data => data.clientUid.eq(clientUid)),
                            syncExpression(DataStoreSchema.SaleProTimezone, Predicates.ALL),
                            syncExpression(DataStoreSchema.SubscriberAdmin, Predicates.ALL),
                        ],
                        maxRecordsToSync: 10000000000,
                    });
                } else {
                    DataStore.configure({
                        errorHandler: (error) => {
                            console.warn('DataStore.configure syncExpressions error', {error});
                        },
                        syncExpressions: [
                            syncExpression(DataStoreSchema.SaleProClients, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsKicker, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsUsers, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProUsers, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProAppCustomers, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProConfigs, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsActivityLogs, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsAdditions, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsAppMails, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsAppMessages, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsAppNotifications, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsAppointment, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsAppStoreNotifications, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsBookmark, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsCheapMessages, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsCustomerLoyality, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsDamages, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsEmployeeActivity, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsEmployeeDocuments, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsEmployeeSalary, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsEmployeeSchedule, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsEmployeeType, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsEmpNotificationPermissions, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsExpenseCategories, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsExpenses, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsFeedbacks, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsGiftCards, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsInvoices, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsLayaways, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsLocations, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsLocationsCounts, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsOnlineStoreSettings, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsOrders, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsPaymentMethods, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsPayrollType, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsPosEmpDashboard, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsProductAttributes, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsProductCategories, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsProducts, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsQuestionsFeedback, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsRegions, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSaleFeedbacks, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsServices, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsAppointmentOptions, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsCloseDay, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsCompetition, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsCountingOptions, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsEmployeeCompetition, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsInventory, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsLayaway, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsObligations, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsOpenDay, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsPrintOptions, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsSaleOptions, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSettingsTaxRules, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsShipments, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSkyAnalysis, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsStorages, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsStoragesCounts, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsStoreConfigs, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsStoreOnlineStoreTemplate, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsStores, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsStoreSkinAnalysis, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsStoreSkyAnalysis, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsStoreTranslate, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsStoreWebOnline, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSubscriptions, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSupplierOrders, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsSuppliers, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsTaskCategories, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsTasks, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsTesters, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsTimelineNote, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsTransfers, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProClientsWebsite, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProDocuments, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProImports, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProQueues, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProSmsPackage, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProSuggestions, Predicates.ALL),
                            syncExpression(DataStoreSchema.SaleProTimezone, Predicates.ALL),
                            syncExpression(DataStoreSchema.SubscriberAdmin, Predicates.ALL),
                        ],
                        maxRecordsToSync: 10000000000,
                    });
                }

                if (clearDataStore) {
                    await this.pauseDataStore();
                    await this.cleanDataStore();
                }
            }

            return true;
        }

        /**
         * Start DataStore again
         */
        async startDataStore() {

            console.log('=== app debug: datastore start calling! ===');

            let packages = await DataStore.query(DataStoreSchema.SaleProSmsPackage, Predicates.ALL);

            let dataStoreStartStatus = await DataStore.start();

            // let dataStoreStartStatus = await DataStore.save(new DataStoreSchema.SaleProClientsActivityLogs({
            //      date: moment().format(),
            //      timezone: 'moment.tz.guess()',
            //      clientUid: 'AppLSFunc.getClientUid(admin)',
            //      uid: 'AppLSFunc.getClientUid(admin)',
            //      operation: 'startSync',
            //      currentPage: 'dataStore.Start',
            //      nextPage: 'startSyncOperation',
            //      subscriberControlOperation: false
            //  }));

            console.log('=== app debug: datastore started ===', `Status: ${dataStoreStartStatus}`);

            return true;
        }

        /**
         * Stop the DataStore sync process
         * @returns {Promise<boolean>}
         */
        async pauseDataStore() {
            await DataStore.stop();
            console.log('=== app debug: datastore stopped! ===');
            return true;
        }

        /**
         * Remove all DataStore data from IndexedDB
         * @returns {Promise<boolean>}
         */
        async cleanDataStore() {
            if (clearDataStore && AppUtilsFunc.isActiveInternetConnection()) {
                await DataStore.clear();
                console.log('=== app debug: datastore clear! ===');
            }
            return true;
        }
    }

    let instance;

    return {
        getInstance: async function () {
            if (!instance) {
                instance = new dataStoreInstance(clientUid, sectionName, changeProcess, clearDataStore);
                await instance.configDataStore();
            }
            return instance;
        },
    };
});

/**
 * Initializing schemas sync expression by client account ID.
 * @param clientUid
 * @param sectionName
 * @param changeProcess
 * @param clearDataStore
 */
export const syncDataByClientAccount = async (clientUid = '', sectionName = 'all', changeProcess = true, clearDataStore = false) => {
    try {
        await AmplifyConfigure.ensureConfigured('datastore-db.js insertDataInAwsDataStore');

        // if ((AppConstants.SALEPRO_APP_BUILD_FRAMEWORK === 'cordova-android' || AppConstants.SALEPRO_APP_BUILD_FRAMEWORK === 'cordova-ios')) {
        //
        //     if (window.syncDataByClientAccountInitialized) {
        //         console.warn('=== app debug: syncDataByClientAccountInitialized already performed. ===');
        //         return;
        //     }
        //
        //     window.syncDataByClientAccountInitialized = true;
        //
        //     console.log('=== app debug: syncDataByClientAccount called ===');
        //
        //     dataStoreConnSingletonInstance = await new dataStoreConnSingleton(clientUid, sectionName, changeProcess, clearDataStore).getInstance();
        //
        //     /**
        //      * note:: we do not need to call this if datastore is initialized from Amplify.configure()
        //      * as per documentation, it will automatically start the datastore
        //      */
        //     await dataStoreConnSingletonInstance.startDataStore();
        //
        // } else {
        //     await applySyncOperationForWebAndDesktopApps(clientUid, sectionName, changeProcess, clearDataStore);
        // }
        //

        if (AppConstants.SALEPRO_APP_BUILD_FRAMEWORK === 'cordova-ios') {
            initDataStoreListener();
        }

        await applySyncOperationForWebAndDesktopApps(clientUid, sectionName, changeProcess, clearDataStore);

        return true;

    } catch (e) {
        console.log('Error syncDataByClientAccount: ', clientUid, sectionName, e);
        return false;
    }
};

/**
 * Apply sync operation for web and desktop
 * @param clientUid
 * @param sectionName
 * @param changeProcess
 * @param clearDataStore
 * @returns {Promise<void>}
 */
const applySyncOperationForWebAndDesktopApps = async (clientUid = '', sectionName = 'all', changeProcess = true, clearDataStore = false) => {
    let syncExpressionsSectionWise = [];
    if (clientUid) {
        if (sectionName === 'my_account') {
            syncExpressionsSectionWise = [
                syncExpression(DataStoreSchema.SubscriberAdmin, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClients, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsKicker, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsUsers, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProImports, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProUsers, data => data.adminUID.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsActivityLogs, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsEmployeeType, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsInvoices, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsLocations, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsLocationsCounts, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsStorages, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsStoragesCounts, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsEmpNotificationPermissions, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsOrders, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsPaymentMethods, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsRegions, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsProductCategories, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsProducts, () => {
                    return data => data.clientUid.eq(clientUid);
                }),
            ];
        } else if (sectionName === 'sales_ticket') {
            syncExpressionsSectionWise = [
                syncExpression(DataStoreSchema.SaleProClients, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsUsers, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProAppCustomers, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProUsers, data => data.adminUID.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsCheapMessages, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsLocations, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsOrders, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSaleFeedbacks, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsServices, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsActivityLogs, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsAppMails, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsAppointmentOptions, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsSaleOptions, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsInventory, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsLayaway, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsProductCategories, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsPrintOptions, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsTaxRules, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsProductCategories, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsProducts, () => {
                    return data => data.clientUid.eq(clientUid);
                }),
            ];
        } else {
            syncExpressionsSectionWise = [
                syncExpression(DataStoreSchema.SubscriberAdmin, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClients, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsKicker, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsUsers, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProUsers, data => data.adminUID.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProAppCustomers, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProConfigs, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsActivityLogs, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsAdditions, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsAppMails, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsAppMessages, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsAppNotifications, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsAppointment, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsAppStoreNotifications, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsBookmark, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsCheapMessages, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsCustomerLoyality, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsDamages, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsEmployeeActivity, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsEmployeeDocuments, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsEmployeeSalary, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsEmployeeSchedule, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsEmployeeType, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsEmpNotificationPermissions, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsExpenseCategories, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsExpenses, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsFeedbacks, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsGiftCards, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsInvoices, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsLayaways, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsLocations, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsLocationsCounts, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsOnlineStoreSettings, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsOrders, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsPaymentMethods, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsPayrollType, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsPosEmpDashboard, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsProductAttributes, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsProductCategories, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsProducts, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsQuestionsFeedback, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsRegions, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSaleFeedbacks, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsServices, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsAppointmentOptions, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsCloseDay, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsCompetition, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsCountingOptions, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsEmployeeCompetition, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsInventory, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsLayaway, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsObligations, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsOpenDay, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsPrintOptions, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsSaleOptions, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSettingsTaxRules, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsShipments, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSkyAnalysis, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsStorages, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsStoragesCounts, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsStoreConfigs, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsStoreOnlineStoreTemplate, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsStores, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsStoreSkinAnalysis, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsStoreSkyAnalysis, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsStoreTranslate, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsStoreWebOnline, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSubscriptions, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSupplierOrders, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsSuppliers, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsTaskCategories, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsTasks, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsTesters, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsTimelineNote, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsTransfers, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProClientsWebsite, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProDocuments, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProImports, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProQueues, data => data.storeUID.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProSmsPackage, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProSuggestions, data => data.clientUid.eq(clientUid)),
                syncExpression(DataStoreSchema.SaleProTimezone, Predicates.ALL),
            ];
        }
        DataStore.configure({
            errorHandler: (error) => {
                console.warn("DataStore.configure errorHandler:", {error});
            },
            syncExpressions: syncExpressionsSectionWise,
            maxRecordsToSync: 10000000000
        });
    } else {
        if (sectionName === 'general-login') {
            syncExpressionsSectionWise = [
                syncExpression(DataStoreSchema.SaleProConfigs, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClients, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProUsers, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsUsers, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProAppCustomers, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsActivityLogs, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSubscriptions, Predicates.ALL),
            ];
        } else {
            syncExpressionsSectionWise = [
                syncExpression(DataStoreSchema.SubscriberAdmin, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClients, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsKicker, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsUsers, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProUsers, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProAppCustomers, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProConfigs, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsActivityLogs, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsAdditions, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsAppMails, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsAppMessages, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsAppNotifications, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsAppointment, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsAppStoreNotifications, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsBookmark, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsCheapMessages, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsCustomerLoyality, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsDamages, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsEmployeeActivity, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsEmployeeDocuments, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsEmployeeSalary, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsEmployeeSchedule, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsEmployeeType, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsEmpNotificationPermissions, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsExpenseCategories, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsExpenses, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsFeedbacks, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsGiftCards, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsInvoices, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsLayaways, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsLocations, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsLocationsCounts, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsOnlineStoreSettings, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsOrders, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsPaymentMethods, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsPayrollType, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsPosEmpDashboard, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsProductAttributes, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsProductCategories, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsProducts, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsQuestionsFeedback, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsRegions, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSaleFeedbacks, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsServices, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSettingsAppointmentOptions, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSettingsCloseDay, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSettingsCompetition, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSettingsCountingOptions, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSettingsEmployeeCompetition, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSettingsInventory, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSettingsLayaway, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSettingsObligations, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSettingsOpenDay, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSettingsPrintOptions, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSettingsSaleOptions, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSettingsTaxRules, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsShipments, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSkyAnalysis, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsStorages, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsStoragesCounts, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsStoreConfigs, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsStoreOnlineStoreTemplate, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsStores, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsStoreSkinAnalysis, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsStoreSkyAnalysis, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsStoreTranslate, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsStoreWebOnline, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSubscriptions, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSupplierOrders, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsSuppliers, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsTaskCategories, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsTasks, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsTesters, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsTimelineNote, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsTransfers, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProClientsWebsite, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProDocuments, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProImports, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProQueues, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProSmsPackage, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProSuggestions, Predicates.ALL),
                syncExpression(DataStoreSchema.SaleProTimezone, Predicates.ALL),
            ];
        }

        DataStore.configure({
            errorHandler: (error) => {
                console.warn("DataStore.configure errorHandler:", {error});
            },
            syncExpressions: syncExpressionsSectionWise,
            maxRecordsToSync: 10000000000
        });
    }
    if (changeProcess) {
        await changeSyncProcess(clearDataStore);
    }
};

/**
 * Stop AppSync
 * Clear Local data if required
 * Start AppSync again
 * @param doDataStoreClear
 * @returns {Promise<void>}
 */
export const changeSyncProcess = async (doDataStoreClear = false) => {
    await DataStore.stop();
    await clearLocalData(doDataStoreClear, true);
};

/**
 * Clear local data
 * Start AppSync
 * @param performClear
 * @param startAgain
 * @returns {Promise<void>}
 */
export const clearLocalData = async (performClear = true, startAgain = false) => {
    if (performClear && AppUtilsFunc.isActiveInternetConnection()) {
        await DataStore.clear();
    }
    if (startAgain) {
        await DataStore.start();
    }
};

const initDataStoreListener = () => {

    if (window.dataStoreHubInit) {
        return;
    }

    window.dataStoreHubInit = true;

    // Create listener
    const dataStoreListener = Hub.listen('datastore', async hubData => {
        const {event, data} = hubData.payload;

        console.warn('called dataStoreListener');

        switch (event) {
            case 'networkStatus':
                JsLoadingOverlay.show();
                console.warn('DataStore networkStatus', data);
                break;
            case 'subscriptionsEstablished':
                console.warn('DataStore subscriptionsEstablished', data);
                break;
            case 'syncQueriesStarted':
                console.warn('DataStore syncQueriesStarted', data);
                break;
            case 'modelSynced':
                console.warn('DataStore modelSynced', data);
                break;
            case 'syncQueriesReady':
                console.warn('DataStore syncQueriesReady', data);
                break;
            case 'ready':
                JsLoadingOverlay.hide();
                dataStoreListener();
                console.warn('DataStore ready', data);
                break;
            case 'outboxMutationEnqueued':
                console.warn('DataStore outboxMutationEnqueued', data);
                break;
            case 'outboxMutationProcessed':
                console.warn('DataStore outboxMutationProcessed', data);
                break;
            case 'outboxStatus':
                console.warn('DataStore outboxStatus', data);
                break;
        }
    });


    // Remove listener
    // dataStoreListener();

};

/**
 * Insert data in AWS Amplify DataStore using dynamic Collection name and data.
 * @param schemaName
 * @param data
 * @param returnId
 * @param defaultIdField
 * @returns {Promise<*|boolean>}
 */
export const insertDataInAwsDataStore = async (schemaName, data, returnId = false, defaultIdField = 'id') => {
    try {
        await AmplifyConfigure.ensureConfigured('amplify-datastore.js insertDataInAwsDataStore');
        const dbInstance = await DataStore.save(new DataStoreSchema[schemaName](data));
        return returnId ? dbInstance[defaultIdField] : true;
    } catch (error) {
        console.log('Error saving data', schemaName, error);
        return false;
    }
};

/**
 * Update data in AWS Amplify DataStore using dynamic Collection name and data.
 * @param schemaName
 * @param id
 * @param data
 * @returns {Promise<boolean>}
 */
export const updateDataInAwsDataStore = async (schemaName, id, data) => {
    try {
        await AmplifyConfigure.ensureConfigured('amplify-datastore.js updateDataInAwsDataStore');
        const original = await DataStore.query(DataStoreSchema[schemaName], id);
        const updatedPost = await DataStore.save(DataStoreSchema[schemaName].copyOf(original, updated => generateUpdateData(updated, data)));
        // console.log('Data updated successfully!', updatedPost);
        return true;
    } catch (error) {
        console.log('Error saving data: ', schemaName, error);
        return false;
    }
};

/**
 * Update Data by condition
 * @param schemaName
 * @param conditionType
 * @param fieldName
 * @param fieldValue
 * @param data
 * @returns {Promise<boolean>}
 */
export const updateDataInAwsDataStoreByCondition = async (schemaName, conditionType, fieldName, fieldValue, data) => {
    try {
        await AmplifyConfigure.ensureConfigured('amplify-datastore.js updateDataInAwsDataStoreByCondition');
        const original = await getDataFromAwsDataStoreByCondition(schemaName, conditionType, fieldName, fieldValue);
        if (original.length === 0) {
            return false;
        }
        const updatedPost = await DataStore.save(DataStoreSchema[schemaName].copyOf(original[0], updated => generateUpdateData(updated, data)));
        // console.log('Data updated successfully!', updatedPost);
        return true;
    } catch (error) {
        console.log('Error saving data: ', schemaName, error);
        return false;
    }
};

/**
 * Update Data by conditions
 * @param schemaName
 * @param conditions
 * @param data
 * @returns {Promise<boolean>}
 */
export const updateDataInAwsDataStoreByConditions = async (schemaName, conditions, data) => {
    try {
        await AmplifyConfigure.ensureConfigured('amplify-datastore.js updateDataInAwsDataStoreByConditions');
        const original = await getDataFromAwsDataStoreByConditions(schemaName, conditions);
        if (original.length === 0) {
            return false;
        }
        const updatedPost = await DataStore.save(DataStoreSchema[schemaName].copyOf(original[0], updated => generateUpdateData(updated, data)));
        // console.log('Data updated successfully!', updatedPost);
        return true;
    } catch (error) {
        console.log('Error saving data: ', schemaName, error);
        return false;
    }
};

/**
 * Generating data as per db instance
 * @param dbInstance
 * @param postData
 * @returns {*}
 */
const generateUpdateData = (dbInstance, postData) => {
    Object.keys(postData).forEach((key) => {
        dbInstance[key] = postData[key];
    });
    return dbInstance;
};

/**
 * Get data from schema by document id
 * @param schemaName
 * @param documentId
 * @returns {Promise<PersistentModel|{}>}
 */
export const getDataFromAwsDataStoreById = async (schemaName, documentId) => {
    try {
        await AmplifyConfigure.ensureConfigured('amplify-datastore.js getDataFromAwsDataStoreById');
        return await DataStore.query(DataStoreSchema[schemaName], documentId);
    } catch (error) {
        console.log('Error get all data: ', schemaName, error);
        return {};
    }
};

/**
 * Get all data from a collection
 * Sort by field and direction
 * @param schemaName
 * @param sortByField
 * @param sortBy
 * @returns {Promise<PersistentModel|{}[]|*[]>}
 */
export const getAllDataFromAwsDataStore = async (schemaName, sortByField = null, sortBy = 'desc') => {
    try {
        await AmplifyConfigure.ensureConfigured('amplify-datastore.js getAllDataFromAwsDataStore');
        if (sortByField) {
            const sortDirection = (sortBy === 'desc') ? SortDirection.DESCENDING : SortDirection.ASCENDING;
            return await DataStore.query(DataStoreSchema[schemaName], Predicates.ALL, {
                sort: (s) => s[sortByField](sortDirection),
            });
        }
        return await DataStore.query(DataStoreSchema[schemaName], Predicates.ALL);
    } catch (error) {
        console.log('Error get all data: ', schemaName, error);
        return [];
    }
};

/**
 * Get all data by condition
 * Sort by field and direction
 * @param schemaName
 * @param conditionType
 * @param fieldName
 * @param fieldValue
 * @param sortByField
 * @param sortBy
 * @returns {Promise<PersistentModel|{}[]|{}>}
 */
export const getDataFromAwsDataStoreByCondition = async (schemaName, conditionType, fieldName, fieldValue, sortByField = null, sortBy = 'desc') => {
    try {
        await AmplifyConfigure.ensureConfigured('amplify-datastore.js getDataFromAwsDataStoreByCondition');

        if (sortByField) {
            const sortDirection = (sortBy === 'desc') ? SortDirection.DESCENDING : SortDirection.ASCENDING;
            return await DataStore.query(DataStoreSchema[schemaName], (c) => c[fieldName][conditionType](fieldValue), {
                sort: (s) => s[sortByField](sortDirection),
            });
        }

        return await DataStore.query(DataStoreSchema[schemaName], (c) => c[fieldName][conditionType](fieldValue));
    } catch (error) {
        console.log('Error get all data: ', schemaName, error);
        return [];
    }
};

/**
 * Get data from data store by multiple conditions
 * @param schemaName
 * @param conditions
 * @param sortByField
 * @param sortBy
 * @param paginationOptions
 * @param orConditions
 * @returns {Promise<PersistentModel|{}[]|{}>}
 */
export const getDataFromAwsDataStoreByConditions = async (schemaName, conditions = [], sortByField = null, sortBy = 'desc', paginationOptions = {}, orConditions = []) => {
    try {
        await AmplifyConfigure.ensureConfigured('amplify-datastore.js getDataFromAwsDataStoreByConditions');

        if (conditions.length > 0) {

            if (sortByField) {
                const sortDirection = (sortBy === 'desc') ? SortDirection.DESCENDING : SortDirection.ASCENDING;
                return await DataStore.query(DataStoreSchema[schemaName], (c) => c.and(c => generateConditionsByInstance(c, conditions)), {
                    sort: (s) => s[sortByField](sortDirection),
                    ...paginationOptions,
                });
            }

            return await DataStore.query(DataStoreSchema[schemaName], (c) => c.and(c => generateConditionsByInstance(c, conditions, orConditions)));

        } else {

            if (sortByField) {
                const sortDirection = (sortBy === 'desc') ? SortDirection.DESCENDING : SortDirection.ASCENDING;
                return await DataStore.query(DataStoreSchema[schemaName], Predicates.ALL, {
                    sort: (s) => s[sortByField](sortDirection),
                    ...paginationOptions,
                });
            }

            return await DataStore.query(DataStoreSchema[schemaName], Predicates.ALL, paginationOptions);
        }
    } catch (error) {
        console.log('Error get all data: ', schemaName, conditions, error);
        return [];
    }
};

/**
 * Listen all data from collection
 * Using order
 * @param schemaName
 * @param callback
 * @param sortByField
 * @param sortBy
 * @param defaultId
 * @param stopSubscription
 */
export const listenAllDataFromCollection = (schemaName, callback, sortByField = null, sortBy = 'desc', defaultId = 'id', stopSubscription = false) => {
    AmplifyConfigure.ensureConfigured('amplify-datastore.js listenAllDataFromCollection').then(() => {
        let queryObserver = DataStore.observe(DataStoreSchema[schemaName]);
        if (sortByField) {
            const sortDirection = (sortBy === 'desc') ? SortDirection.DESCENDING : SortDirection.ASCENDING;
            queryObserver = DataStore.observeQuery(DataStoreSchema[schemaName], Predicates.ALL, {
                    sort: s => s[sortByField](sortDirection),
                },
            );
        }
        const subscription = queryObserver.subscribe(snapshot => {
            // console.log('snapshot', snapshot);
            // console.log(snapshot.model, snapshot.opType, snapshot.element);
            const {items, isSynced} = snapshot;
            callback(generateDataInObjectFormat(items, defaultId));
        });
        if (stopSubscription) {
            subscription.unsubscribe();
        }
    });
};

/**
 * Listen Collection CRUD EVENT AND DATA
 * @param schemaName
 * @param handleCreateCallback
 * @param handleUpdateCallback
 * @param handleDeleteCallback
 * @param conditions
 * @param defaultIdField
 * @param stopSubscription
 */
export const listenAllEventAndDataFromCollection = (schemaName, handleCreateCallback, handleUpdateCallback, handleDeleteCallback, conditions = [], defaultIdField = 'id', stopSubscription = false) => {
    AmplifyConfigure.ensureConfigured('amplify-datastore.js listenAllEventAndDataFromCollection').then(() => {
        const subscription = DataStore.observe(DataStoreSchema[schemaName]).subscribe(snapshot => {

            // console.log('snapshot', snapshot);
            // console.log(snapshot.model, snapshot.opType, snapshot.element);

            if (conditions.length > 0) {
                let okCondition = conditions.every((condition) => {
                    if (condition.type === 'equal') {
                        return snapshot.element[condition.field] === condition.value;
                    } else if (condition.type === 'includes' && condition.value instanceof Array) {
                        return condition.value.includes(snapshot.element[condition.field]);
                    } else if (condition.type === 'includes') {
                        return snapshot.element[condition.field].includes(condition.value);
                    } else {
                        return false;
                    }
                });
                if (okCondition) {
                    if (snapshot?.opType === 'INSERT') {
                        handleCreateCallback(snapshot.element);
                    } else if (snapshot?.opType === 'UPDATE') {
                        handleUpdateCallback(snapshot.element);
                    } else if (snapshot?.opType === 'DELETE') {
                        handleDeleteCallback(snapshot.element[defaultIdField]);
                    }
                }
            } else {
                if (snapshot?.opType === 'INSERT') {
                    handleCreateCallback(snapshot.element);
                } else if (snapshot?.opType === 'UPDATE') {
                    handleUpdateCallback(snapshot.element);
                } else if (snapshot?.opType === 'DELETE') {
                    handleDeleteCallback(snapshot.element[defaultIdField]);
                }
            }
        });
        if (stopSubscription) {
            subscription.unsubscribe();
        }
    });
};

/**
 * Listen real time data from collection by a document ID
 * @param schemaName
 * @param documentId
 * @param callback
 * @param stopSubscription
 */
export const listenDataFromCollectionById = (schemaName, documentId, callback, stopSubscription = false) => {
    AmplifyConfigure.ensureConfigured('amplify-datastore.js listenDataFromCollectionById').then(() => {
        const subscription = DataStore.observe(DataStoreSchema[schemaName], documentId).subscribe(snapshot => {
            callback(snapshot?.element || {});
        });
        if (stopSubscription) {
            subscription.unsubscribe();
        }
    });
};

// export const testOrFunction = (callback) => {
//     const queryObserver = DataStore.observeQuery(DataStoreSchema.SaleProClientsProducts,
//         (c) => c.and(d => [
//             d.clientUid.eq('6b9e1305-388f-48a2-a67e-5f823898be06'),
//             d.trash.eq(false),
//             d.or(e => [
//                 e.type.contains('product'),
//                 e.type.beginsWith('product_pack')
//             ])
//         ]));
//     const subscription = queryObserver.subscribe(snapshot => {
//         const {items, isSynced} = snapshot;
//         callback(items);
//     });
// };

/**
 * Listen real time data from collection
 * Using conditions
 * Using order
 * @param schemaName
 * @param callback
 * @param conditions
 * @param sortByField
 * @param sortBy
 * @param defaultIdField
 * @param orConditions
 * @param stopSubscription
 */
export const listenDataFromCollectionByCondition = (schemaName, callback, conditions = [], sortByField = null, sortBy = 'desc', defaultIdField = 'id', orConditions = [], stopSubscription = false) => {
    AmplifyConfigure.ensureConfigured('amplify-datastore.js listenDataFromCollectionByCondition').then(() => {

        let queryObserver;

        if (conditions.length > 0) {
            if (sortByField) {
                const sortDirection = (sortBy === 'desc') ? SortDirection.DESCENDING : SortDirection.ASCENDING;
                queryObserver = DataStore.observeQuery(DataStoreSchema[schemaName], (c) => c.and(c => generateConditionsByInstance(c, conditions, orConditions)), {
                        sort: s => s[sortByField](sortDirection),
                    },
                );
            } else {
                queryObserver = DataStore.observeQuery(DataStoreSchema[schemaName], (c) => c.and(c => generateConditionsByInstance(c, conditions, orConditions)));
            }
        } else {
            if (sortByField) {
                const sortDirection = (sortBy === 'desc') ? SortDirection.DESCENDING : SortDirection.ASCENDING;
                queryObserver = DataStore.observeQuery(DataStoreSchema[schemaName], Predicates.ALL, {
                        sort: s => s[sortByField](sortDirection),
                    },
                );
            } else {
                queryObserver = DataStore.observeQuery(DataStoreSchema[schemaName], Predicates.ALL);
            }
        }
        const subscription = queryObserver.subscribe(snapshot => {
            const {items, isSynced} = snapshot;
            callback(generateDataInObjectFormat(items, defaultIdField));
        });
        if (stopSubscription) {
            subscription.unsubscribe();
        }

    });
};

/**
 * Generate conditions array
 * @param c
 * @param andConditions
 * @param orConditions
 * @returns {*[]}
 */
const generateConditionsByInstance = (c, andConditions = [], orConditions = []) => {
    let newConditions = [];
    if (andConditions.length > 0) {
        andConditions.forEach((data) => {
            newConditions.push(c[data.fieldName][data.conditionType](data.fieldValue));
        });
    }
    if (orConditions.length > 0) {
        newConditions.push(c.or(q => generateConditionsByInstance(q, orConditions)));
    }
    return newConditions;
};

/**
 * Generate in object format
 * @param items
 * @param defaultIdField
 * @returns {{}}
 */
const generateDataInObjectFormat = (items, defaultIdField = 'id') => {
    let dataObj = {};
    if (items instanceof Array) {
        items.forEach((doc) => {
            dataObj[doc[defaultIdField]] = doc;
        });
    } else {
        dataObj[items[defaultIdField]] = items;
    }
    return dataObj;
};

/**
 * Delete data from datastore by document id.
 * @param schemaName
 * @param documentId
 * @returns {Promise<boolean>}
 */
export const deleteDataFromAwsDataStore = async (schemaName, documentId) => {
    try {
        await AmplifyConfigure.ensureConfigured('amplify-datastore.js deleteDataFromAwsDataStore');
        const toDelete = await DataStore.query(DataStoreSchema[schemaName], documentId);
        if (toDelete) {
            await DataStore.delete(toDelete);
        }
        return true;
    } catch (error) {
        console.log('Error deleting data: ', schemaName, documentId, error);
        return false;
    }
};

/**
 * Delete all data by condition
 * @param schemaName
 * @param fieldName
 * @param fieldValue
 * @returns {Promise<boolean>}
 */
export const deleteMultipleDataFromAwsDataStore = async (schemaName, fieldName, fieldValue) => {
    try {
        await AmplifyConfigure.ensureConfigured('amplify-datastore.js deleteMultipleDataFromAwsDataStore');
        await DataStore.delete(DataStoreSchema[schemaName], (data) => data[fieldName].eq(fieldValue));
        return true;
    } catch (error) {
        console.log('Error deleting data: ', error);
        return false;
    }
};

/**
 * Delete all data by conditions
 * @param schemaName
 * @param conditions
 * @returns {Promise<boolean>}
 */
export const deleteMultipleDataFromAwsDataStoreByConditions = async (schemaName, conditions = []) => {
    try {
        await AmplifyConfigure.ensureConfigured('amplify-datastore.js deleteMultipleDataFromAwsDataStoreByConditions');
        await DataStore.delete(DataStoreSchema[schemaName], (c) => c.and(c => generateConditionsByInstance(c, conditions)));
        return true;
    } catch (error) {
        console.log('Error deleting data: ', error);
        return false;
    }
};

/**
 * Delete all data from a collection.
 * @param schemaName
 * @returns {Promise<boolean>}
 */
export const deleteAllDataFromAwsDataStore = async (schemaName) => {
    try {
        await AmplifyConfigure.ensureConfigured('amplify-datastore.js deleteAllDataFromAwsDataStore');
        await DataStore.delete(DataStoreSchema[schemaName], Predicates.ALL);
        return true;
    } catch (error) {
        console.log('Error deleting data: ', schemaName, error);
        return false;
    }
};
