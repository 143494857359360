import * as AppConstants from '../database/constants.js';
import * as AppUtilsFunc from "../database/utils.js";
import * as AppLsFunc from '../database/localStorage.js';
import * as RenderSystem from '../render/render-system.js';
import * as UserController from '../database/db/subscriber-admin-db.js';

import * as AmplifyDbController from '../amplify-common/datastore-db.js';
import * as AmplifyAuthController from '../amplify-common/auth-db.js';
import * as Aws_AdminSDK from "../amplify-common/aws-admin-functions.js";

import {initClients, initSuggestions, renderClients} from './clients.js';
import {applyConfigEvents, renderSaleProConfigs} from "./salepro-config.js";

let g_loggedInUser = null;
let g_subscriber_admins = null;

$(document).ready(async function () {

    try {

        $('.salepro-subscriber-admin-version').html(AppConstants.SALEPRO_SUBSCRIBER_CONTROL_URL_VERSION);

        RenderSystem.showLoadingDataMessage();

        // check if user is logged in
        UserController.checkIsLoggedIn(initSubscriberAdminDashboard);

    } catch (e) {
        console.log('Error: ', e.message);

        RenderSystem.hideLoadingDataMessage();
    }

});

/**
 * Initializes the Subscriber Admin Dashboard.
 *
 * @param {boolean} status - The status indicating if the user is authenticated or not.
 * @param {object} user - The user object containing user information.
 *
 * @return {Promise} - A promise that resolves when the Subscriber Admin Dashboard is initialized successfully.
 */
async function initSubscriberAdminDashboard(status, user) {
    if (!status) {
        window.location = 'login.html';
    } else {

        await AmplifyDbController.syncDataByClientAccount('', 'all', true);

        if (await UserController.isSubscriberAdminUserById(user.username)) {

            g_loggedInUser = await UserController.getSubscriberAdminUserById(user.username);

            $('body').show();

            $('#subscriberID').text(g_loggedInUser.email);

            initClients(g_loggedInUser);

            await initSuggestions();
            await renderSaleProConfigs();

            applyEvents();

        } else {
            RenderSystem.hideLoadingDataMessage();
            UserController.logoutUser();
            window.location = 'login.html';
        }
    }
}

/**
 * Applies various event listeners and handlers to different elements in the application.
 *
 * The method applies the following events:
 * - showHidePasswordEvent: applies the show/hide password event.
 * - listenSubscriberAdmins: attaches an event listener to the UserController to listen to subscriber admins data.
 * - modal events: attaches 'show.bs.modal' and 'shown.bs.modal' events to all modals for z-index and backdrop handling.
 * - logout-user click: attaches a click event to the #logout-user element to logout the user.
 * - applyConfigEvents: invokes the applyConfigEvents() method.
 * - gridViewClients click: attaches a click event to the #gridViewClients element to show the client grid view.
 * - listViewClients click: attaches a click event to the #listViewClients element to show the client list view.
 * - searchClient keyup and change: attaches keyup and change events to the #searchClient input for client searching.
 * - clientUIDModal hidden.bs.modal: attaches a hidden.bs.modal event to #clientUIDModal for cleaning up and logging out the admin user.
 * - createInvoicesModal hidden.bs.modal: attaches a hidden.bs.modal event to #createInvoicesModal for cleaning up and resetting the invoice view.
 * - showInvoiceModal click: attaches a click event to #showInvoiceModal to show the invoices view.
 * - createSubscriberAdminModalButton click: attaches a click event to #createSubscriberAdminModalButton to reset the create subscriber admin form.
 * - createSubscriberAdminModal saveAdminForm: attaches a validate and submit handler to the #createSubscriberAdminModal form for creating and updating subscriber admins.
 *
 * @return void
 */
function applyEvents() {

    AppUtilsFunc.showHidePasswordEvent();

    UserController.listenSubscriberAdmins((data) => {
        g_subscriber_admins = data;
    });

    $('.modal').on('show.bs.modal', function (event) {
        var idx = $('.modal:visible').length;
        $(this).css('z-index', 1040 + (10 * idx));
    });
    $('.modal').on('shown.bs.modal', function (event) {
        var idx = ($('.modal:visible').length) - 1; // raise backdrop after animation.
        $('.modal-backdrop').not('.stacked').css('z-index', 1039 + (10 * idx));
        $('.modal-backdrop').not('.stacked').addClass('stacked');
    });

    $('#logout-user').click(async function () {
        RenderSystem.showLoadingDataMessage('Please wait a moment...');
        await UserController.logoutUser();
        AppUtilsFunc.clearBrowserCache();
        RenderSystem.hideLoadingDataMessage();
        window.location = 'login.html';
    });

    applyConfigEvents();

    $('#gridViewClients').click(function () {
        $('#client-grid-wrapper').show();
        $('#client-list-wrapper').hide();
    });

    $('#listViewClients').click(function () {
        $('#client-grid-wrapper').hide();
        $('#client-list-wrapper').show();
    });

    $('#searchClient').on('keyup change', function (e) {
        renderClients($(this).val() || '');
    });

    $('#clientUIDModal').on('hidden.bs.modal', function () {

        $('#docView').attr('src', '');

        AppLsFunc.logout(AppConstants.USER_ROLE.ADMIN.id);

        if ($('div.store-account-details').data('blockUI.isBlocked')) {
            $('div.store-account-details').unblock();
        }

        $('#showInvoiceModal').attr('disabled', false);

        let subscriberAdminCredentials = UserController.getSubscriberAdminData();

        // console.log('subscriberAdminCredentials', subscriberAdminCredentials);

        if (subscriberAdminCredentials?.uid) {
            AmplifyAuthController.isLoggedIn(true).then(async (user) => {
                if (user.username !== subscriberAdminCredentials.uid) {
                    await AmplifyAuthController.logoutUser();
                    await AmplifyAuthController.loginUser(subscriberAdminCredentials.email, subscriberAdminCredentials.password, false).catch((err) => {
                        RenderSystem.hideLoadingDataMessage(true);
                        RenderSystem.showAlertMessage('Please login again to perform any operation.', 'danger');
                    });
                }
            });
        }
    });

    $('#createInvoicesModal').on('hidden.bs.modal', function () {
        $('#invoicesView').attr('src', '');
    });

    $('#showInvoiceModal').click(function () {
        $('#invoicesView').attr('src', './invoices-section/invoices.html');
        $('#createInvoicesModal').modal('show');
    });

    $('#createSubscriberAdminModalButton').click(function () {
        $('#createSubscriberAdminModal #saveAdminForm #adminId').val('');
        $('#createSubscriberAdminModal #saveAdminForm')[0].reset();
    });

    $('#createSubscriberAdminModal #saveAdminForm').validate({
        ignore: [],
        errorPlacement: function errorPlacement(error, element) {
            $(element).parents('div.form-group').find('span.validation-errors').append(error);
        },
        onfocusout: false,
        highlight: function (element, errorClass) {
            $(element).addClass(errorClass);
        },
        unhighlight: function (element, errorClass) {
            $(element).removeClass(errorClass);
        },
        submitHandler: function (form) {
            if ($(form).valid()) {

                let adminId = $('#createSubscriberAdminModal #adminId').val() || null;

                let postData = {
                    name: $('#createSubscriberAdminModal #adminName').val(),
                    email: $('#createSubscriberAdminModal #adminEmail').val(),
                    password: $('#createSubscriberAdminModal #adminPassword').val(),
                    phone: $('#createSubscriberAdminModal #adminPhone').val(),
                    status: $('#createSubscriberAdminModal #adminStatus').val() === 'Active',
                    type: 'admin',
                    addAdminPermission: $('#createSubscriberAdminModal #manageSubscriberAdmin').is(':checked'),
                    readOnlyPermission: false,
                    modifyDataPermission: true,
                };

                RenderSystem.showLoadingDataMessage('Please wait a moment...');

                if (adminId) {
                    postData['saId'] = adminId;

                    if ((postData.email !== g_subscriber_admins[adminId].email)) {
                        postData['oldEmail'] = g_subscriber_admins[adminId].email;
                    }

                    if ((postData.password !== g_subscriber_admins[adminId].password)) {
                        postData['oldPassword'] = g_subscriber_admins[adminId].password;
                    }

                    let doAuthUpdateByForceLogin = false;

                    if (g_loggedInUser.email === g_subscriber_admins[adminId].email && (postData.email !== g_loggedInUser.email || postData.password !== g_subscriber_admins[adminId].password)) {
                        doAuthUpdateByForceLogin = true;
                    }

                    UserController.updateUserWithCredentials(postData, (postData.email !== g_subscriber_admins[adminId].email), (postData.password !== g_subscriber_admins[adminId].password)).then(async (response) => {

                        if (doAuthUpdateByForceLogin) {

                            let subscriberAdminCredentials = UserController.getSubscriberAdminData();

                            if (subscriberAdminCredentials) {
                                AmplifyAuthController.isLoggedIn(true).then(async (user) => {

                                    if (user?.username) {
                                        await AmplifyAuthController.logoutUser();
                                    }

                                    await AmplifyAuthController.loginUser(subscriberAdminCredentials.email, subscriberAdminCredentials.password, false);
                                }).catch((err) => console.log(err));
                            }
                        }

                        RenderSystem.hideLoadingDataMessage();

                        if (response.status === AppConstants.RES_STATUS.SUCCESS) {

                            RenderSystem.showAlertMessage("Updated successfully!", "success");

                            $('#createSubscriberAdminModal #saveAdminForm #adminId').val('');
                            $('#createSubscriberAdminModal #saveAdminForm')[0].reset();
                            $('#createSubscriberAdminModal').modal('hide');

                        } else if (response.status === AppConstants.RES_STATUS.EMAIL_EXIST) {

                            RenderSystem.showAlertMessage("A user with the same email exists", "danger");

                        } else {

                            RenderSystem.showAlertMessage("Failed to update user information", "danger");

                        }

                    }).catch((e) => {

                        console.log('Error: ', e);

                        RenderSystem.hideLoadingDataMessage();

                        RenderSystem.showAlertMessage('Failed to update user information', 'danger');
                    });

                } else {
                    UserController.addAdminUser(postData).then((status) => {

                        RenderSystem.hideLoadingDataMessage();

                        if (status) {

                            // Aws_AdminSDK.AWS_confirmNewAccount(postData.email);

                            RenderSystem.showAlertMessage('Admin user added successfully.', 'success');

                            $('#createSubscriberAdminModal #saveAdminForm #adminId').val('');
                            $('#createSubscriberAdminModal #saveAdminForm')[0].reset();
                            $('#createSubscriberAdminModal').modal('hide');

                        } else {
                            RenderSystem.showAlertMessage('Failed to add admin user.', 'danger');
                        }

                    }).catch((e) => {

                        console.log('Error: ', e);

                        RenderSystem.hideLoadingDataMessage();

                        RenderSystem.showAlertMessage('Failed to add admin user.', 'danger');
                    });
                }
            }
        },
    });
}
