import * as RenderSystem from '../render/render-system.js';
import * as UserController from '../database/db/subscriber-admin-db.js';

export function checkImageExistOrNot(imageSrc) {
  // var xhr = new XMLHttpRequest();
  // xhr.open('HEAD', imageSrc, false);
  // xhr.send();
  // if (xhr.status === 404) {
  //   return './images/no-image-found.png';
  // } else {
    return imageSrc;
  // }
}

// Checking notification permission
// document.addEventListener('DOMContentLoaded', function() {
//   if (!Notification) {
//     RenderSystem.showAlertMessage('This browser does not support notifications.', 'danger');
//     return;
//   }
//   if (Notification.permission !== 'granted') {
//     Notification.requestPermission().then(function(getperm) {
//       if (getperm === 'denied') {
//         RenderSystem.showAlertMessage('You denied browser notification. You will not be able to see any notifications of your store operations.', 'danger');
//       }
//     });
//   } else {
//     // notification already granted.
//   }
// });
