import {saveClientData} from '../database/db/salepro-clients-db.js';
import {getClientUid} from '../database/localStorage.js';
import * as RenderSystem from '../render/render-system.js';
import {renderClients} from './clients.js';

let stars;
document.addEventListener('DOMContentLoaded', () => {
  stars = document.querySelectorAll('.reviewStars span');
  stars.forEach(item => {
    item.removeEventListener('click', addRatingEvent);
    item.addEventListener('click', async function () {
      await addRatingEvent(this);
    });
  });
});

const addRatingEvent = async (item) => {
  var rating = item.getAttribute('data-rating');
  var storeOwnerUID = item.getAttribute('data-uid');
  await setRatingStar(rating, stars, storeOwnerUID);
};


/**
 * SetRatingStar sets the rating on page
 *
 * @param {int} rating           Int of the rating value.
 * @param stars
 * @param storeOwnerUID
 * @returns {object} stars       html stars elements
 *
 */
export async function setRatingStar(rating = 0, stars = [], storeOwnerUID = '') {
  var len = stars.length;
  for (var i = 0; i < len; i++) {
    if (i < rating) {
      stars[i].innerHTML = '<i class="fa fa-star active"></i>';
    } else {
      stars[i].innerHTML = '<i class="fa fa-star-o"></i>';
    }
  }
  console.log(storeOwnerUID, rating);
  if(storeOwnerUID) {
    await saveClientRatings(storeOwnerUID, rating);
  }
}

async function saveClientRatings(storeOwnerUID, rating = 0) {
  if (getClientUid().toString() === storeOwnerUID.toString()) {
    await saveClientData(storeOwnerUID, {ratings: Number(rating)});
    RenderSystem.showAlertMessage('Ratings saved successfully.');
    await renderClients();
    return true;
  }
  RenderSystem.showAlertMessage('Invalid operation performed!', 'danger');
  return false;
}
